
import { useState, useEffect } from "react";
import { getAwardTypes } from "../Utils/API"

import {
    Cards,
    Box,
    TextFilter,
    Button,
    Pagination,
    CollectionPreferences,
    Header
  }
  from "@awsui/components-react";
  import { Link } from "react-router-dom";

let AwardsCatalog = () => {

    const [awardTypes, setAwardTypes] = useState([]);
    const [loading, setLoading] = useState(true);

    const [selectedItems,setSelectedItems] = useState([]);

    useEffect(() => {
        setLoading(true);
        getAwardTypes().then(
            (result) => {
                console.log(result)
                setAwardTypes(result['Items'])
                setLoading(false)
            },
            (error) => {
                console.log(error);
                setLoading(false);
            }
        );
    }, []);

    return(
        <Cards
        loading={loading}
        onSelectionChange={({ detail }) =>
            setSelectedItems(detail.selectedItems)
        }
        selectedItems={selectedItems}
        ariaLabels={{
            itemSelectionLabel: (e, t) => `select ${t.name}`,
            selectionGroupLabel: "Item selection"
        }}
        cardDefinition={{
            header: (e) => <Link  to={{pathname: "/campaign/new/", state: {type: e.name, desc: e.description}}}> { e.name}</Link>,
            sections: [
            {
                id: "description",
                header: "Description",
                content: e => e.description
            }
            ]
        }}
        cardsPerRow={[
            { cards: 1 },
            { minWidth: 500, cards: 2 }
        ]}
        items={awardTypes}
        loadingText="Loading resources"
        trackBy="name"
        visibleSections={["description"]}
        empty={
            <Box textAlign="center" color="inherit">
            <b>No Award Definitions</b>
            <Box
                padding={{ bottom: "s" }}
                variant="p"
                color="inherit"
            >
                No awards to display.
            </Box>
            </Box>
        }
        header={
            <Header
            >
            Award Types
            
            </Header>
        }
        selectionType=""
        />
    )

}

export default AwardsCatalog;