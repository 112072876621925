import {
    Box,
    TextFilter,
    Button,
    Pagination,
    CollectionPreferences,
    Header,
    Table,
    Grid,
    StatusIndicator,
    Link,
    SpaceBetween,
    Icon,
    Modal,
    FormField,
    Textarea,
    Select,
    
  }
  from "@awsui/components-react";

  import { useEffect, useState } from "react";

  import { 
    addUpvote
} from "../Utils/API"

let NominationModal = (props) => {
    let alias = sessionStorage.getItem('alias')
    const [ vote, setVote] = useState({
        "alias": alias,
        "id": props.nomination['id'],
        "nom-timestamp": props.timestamp,
        "comment": ""
    })

    return (
        <div>
            <Modal
                visible={props.isVisible}
                onDismiss={() => {
                    props.setIsVisible(false)
                }}
                header={"View Nomination"}
            >
                <SpaceBetween direction="vertical" size="xl" >
                <div>Nomination for {props.nomination['name']}.</div>
                <div><i>Nominated by {props.nomination['nominator-alias']}.</i></div>
                    <FormField label="Situation"  >
                      <Textarea readOnly value={props.nomination.situation}  />
                  </FormField>
                  <FormField label="Action" >
                      <Textarea readOnly value={props.nomination.action}  />
                  </FormField>
                  <FormField label="Impact" >
                      <Textarea readOnly value={props.nomination.impact}  />
                  </FormField>
                  <FormField label="Leadership Principle" >
                      <Textarea readOnly value={props.nomination?.lp}  />
                  </FormField>
                </SpaceBetween>
            </Modal>

        </div>
    )
}

export default NominationModal;