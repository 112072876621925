import { useState, useEffect } from "react";
import { getCampaings } from "../Utils/API"

import {
    Cards,
    Box,
    TextFilter,
    Button,
    Pagination,
    CollectionPreferences,
    Header
  }
  from "@awsui/components-react";

let RecentCampaigns = () => {
    const [campaigns, setCampaigns] = useState([]);
    const [loading, setLoading] = useState(true);

    const [ selectedItems, setSelectedItems ] = useState([]);
    let alias = sessionStorage.getItem('alias')

    useEffect(() => {
        setLoading(true);
        console.log("getting campaigns")
        getCampaings(alias).then(
            (result) => {
                console.log(typeof(result))
                if(typeof result === 'object' && result.length > 0){
                    setCampaigns(result)
                }
                setLoading(false)
            },
            (error) => {
                console.log("error")
                console.log(error);
                setLoading(false);
            }
        );
    }, []);
    return(
        <Cards
        loading={loading}
        onSelectionChange={({ detail }) => {
            console.log(detail.selectedItems[0])
            setSelectedItems(detail.selectedItems)
        }}
        selectedItems={selectedItems}
        ariaLabels={{
            itemSelectionLabel: (e, t) => `select ${t.name}`,
            selectionGroupLabel: "Item selection"
        }}
        cardDefinition={{
            header: e => e.name,
            sections: [
            {
                id: "description",
                header: "Description",
                content: e => e.description
            },
            {
                id: "start",
                header: "Start Date",
                content: e => e['start-date']
            },
            {
                id: "end",
                header: "End Date",
                content: e => e['end-date']
            },
            {
                id: "type",
                header: "Award Type",
                content: e => e['award-type']
            },
            {
                id: "owner",
                header: "Owner",
                content: e => e.owner
            },
            {
                id: "id",
                header: "id",
                content: e => e.id
            }
            ]
        }}
        cardsPerRow={[
            { cards: 1 },
            { minWidth: 500, cards: 2 }
        ]}
        items={campaigns}
        loadingText="Loading Campaigns"
        trackBy="id"
        visibleSections={["description", "type", "start", "end", "owner"]}
        empty={
            <Box textAlign="center" color="inherit">
            <b>No Active Campaigns</b>
            <Box
                padding={{ bottom: "s" }}
                variant="p"
                color="inherit"
            >
                No campaigns to display.
            </Box>
            </Box>
        }
        header={
            <Header
            actions={
                <Button variant="primary" href={"/nomination/" + selectedItems[0]?.id}
                disabled={selectedItems.length == 0 ? true : false}
                >
                    Nominate
                </Button>
            }
            >
            Available Campaigns
            
            </Header>
        }
        selectionType="single"
        />

    )

}

export default RecentCampaigns;