import {
    Button,
    Container,
    Form,
    FormField,
    Header,
    Input,
    Select,
    SpaceBetween,
    Textarea as TextArea,
    DatePicker,
    Checkbox,
    Table,
    Box,
    Modal,
    RadioGroup,
    Multiselect,
    Icon,
    TokenGroup,
    Tiles
  }
  from "@awsui/components-react";
  import { useState, useEffect } from "react";
  import { useHistory, useParams, useLocation } from "react-router-dom";
  import { addCampaign, getAwardTypes, getManagers } from "../Utils/API"
  import { devLogger } from "../Utils/Logging";
import ScopeModal from "./ScopeModal";


let alias =  sessionStorage.getItem('alias')

var m = new Date();
var dateString = m.getUTCFullYear() +"-"+ (m.getUTCMonth()+1) +"-"+ m.getUTCDate() + " " + m.getUTCHours() + ":" + m.getUTCMinutes() + ":" + m.getUTCSeconds();                                 




  let onSave = (campaign, showFlashbar) => {
    console.log("Submiting Campaign")
    //console.log(nomination)
    addCampaign(campaign).then(
        (result) => {
            console.log(result)
        },
        (error) => {
            console.log(error)
        }
    )
    showFlashbar("success", "Campaign Successfully Created")
  }

  function findSelected(inItem, items) {
    if (inItem) {
      for (let i in items) {
        if (items[i].value === inItem){
            return { value: items[i].value, label: items[i].value }
        }
      }
      console.log('findSelected not found', inItem);
    }
    return null;
  }

  let emptyTask = {
    name: "",
    due: ""
  } 

  let getScopeTokens = (scopeList) => {
    let scopeTokens = []
    scopeList.forEach((x, i) => {
      if(x.type === "org"){
        let tags = []
        if(x['ic']){
          tags.push('IC')
        }
        if(x['manager']) {
          tags.push('Manager')
        }
        scopeTokens.push({
            label: x.alias,
            labelTag: "Org",
            tags: tags,
            dismissLabel: "Remove Scope",
            iconName: "share",
        })
      }
      else if(x.type === "user"){
        scopeTokens.push({
            label: x.alias,
            labelTag: "User",
            description: x.alias,
            dismissLabel: "Remove Scope",
            iconName: "user-profile"
        })
      }
    })
    return scopeTokens
  }


let NewCampaign = (props) => {
    let history = useHistory();
    const location  = useLocation()
    let existingCampaign = location?.['state']?.['campaign'];

    let type = null
    let desc = null
    if(location){
      if(location.campaign){

      }
      else {
        type  = location?.state?.type
        desc = location?.state?.desc
      }
     
    }
    

    //console.log(type)

    let initType = ""
    let initDescription = ""

    let initNomineeScopeTokens = [];
    let initNominatorScopeTokens = []
    let initNewTasks = [];

    let initEditMode = false;

    let emptyCampaign = {
      "start-date": "",
      "end-date": "",
      "award-type": initType,
      "description": initDescription,
      "name": "",
      "owner": alias,
      "coowners": [],
      "status": "open",
      "timestamp": dateString,
      "winner": [],
      "NomineeScope": [],
      "NominatorScope": [],
      "winner-nomination-id": "",
      "tasks": []
    }
    

    let initCampaign = emptyCampaign;
    if(existingCampaign != undefined){
      initCampaign = structuredClone(existingCampaign);
      //console.log(initCampaign)
      initNomineeScopeTokens = getScopeTokens(existingCampaign?.['NomineeScope'])
      initNominatorScopeTokens = getScopeTokens(existingCampaign?.['NominatorScope'])
      initNewTasks = structuredClone(existingCampaign?.['tasks'])
      initEditMode = true;
    }


    const [ isEditMode, setIsEditMode ]= useState(initEditMode)

    const [selectedTasks,setSelectedTasks] = useState([]);
    const [modalVisible, setModalVisible] = useState(false);
    const [nomineeModalVisible, setNomineeModalVisible] = useState(false);
    const [nominatorModalVisible, setNominatorModalVisible] = useState(false);
    const [ nomineeScopeTokens, setNomineeScopeTokens ] = useState(initNomineeScopeTokens)
    const [ nominatorScopeTokens, setNominatorScopeTokens ] = useState(initNominatorScopeTokens)
    
    const [newTask, setNewTask] = useState(emptyTask);
    const [newTasks, setNewTasks] = useState(initNewTasks);
    const [managers, setManagers ] = useState([]);
    const [ searchingManagers, setSearchingManagers ] = useState(false)
    const [ selectedManagers, setSelectedManagers ] = useState([])
    const [ isCustomType, setIsCustomType ] = useState(false)
    const [awardTypes, setAwardTypes] = useState([]);

    //Campaign Validation Values
    const [ awardTypeError, setAwardTypeError ] = useState("")
    const [ customAwardTypeError, setCustomAwardTypeError ] = useState("")
    const [ campaignNameError, setCampaignNameError ] = useState("")
    const [ descriptionError, setDescriptionError ] = useState("")
    const [ ownerError, setOwnerError ] = useState("")
    const [ coOwnerError, setCoOwnerError ] = useState("")
    const [ scopeError, setScopeError ] = useState("")
    const [ campaignStartError, setCampaignStartError ] = useState("")
    const [ campaignEndError, setCampaignEndError ] = useState("")
    const [ nomineeScopeError, setNomineeScopeError ] = useState("")
    const [ nominatorScopeError, setNominatorScopeError ] = useState("")
    const [ formError, setFormError ] = useState("")

    //New Task Validation Values
    const [ newTaskNameError, setNewTaskNameError ] = useState("")
    const [ newTaskDateError, setNewTaskDateError ] = useState("")

    const [ taskTypes, setTaskTypes ] = useState([
      { value: "Gift Card", label: "Gift Card" },
      { value: "Send Email Announcement", label: "Send Email Announcement" },
      { value: "1 Day of PTO", label: "1 Day of PTO" },
      { value: "Send Accolade", label: "Send Accolade" }
    ])



    if(type){
      initType = type;
      initDescription = desc;
    }

    
    const [campaign, setCampaign] = useState(initCampaign);
    
    useEffect(() => {
        getAwardTypes().then(
            (result) => {
                //console.log(result)
                let types = []
                types.push({label: "Custom", value: "Custom", description: "Custom Award Type"})
                result['Items'].forEach((t) => {
                  types.push({ label: t.name, value: t.name, description: t.description})
                })
                
                setAwardTypes(types)
            },
            (error) => {
                console.log(error);
            }
        );
    }, []);

    let getManagersWrapper = (chars) => {
      devLogger("Searching for Managers")
      //wait for 3 charws to be typed
      if(chars.length > 2){
        getManagers(chars).then(
          (result) => {
              //console.log(result)
              let managers = []
              result['Items'].forEach((t) => {
                managers.push({ label: t.name, value: t.alias, description: t.alias})
              })
              setManagers(managers)
              setSearchingManagers(false)
          },
          (error) => {
              console.log(error);
          }
        );
      }
        
    }

    let findManagerById = (id) => {
      selectedManagers.forEach((x, i) => {
        if (x['value'] === id){
          return x
        } else {
          return { label: id, value: id }; //fallback
        }
      })
    }

    let toManagersArray = (managersList) => {
      let outputList = []
      managersList.forEach((x, i) => {
        outputList.push(findManagerById(x))
      })
      return outputList
    }




    let validateForm = () => {
      let validationResult = true;
      if(campaign['award-type']  === ""){
        setAwardTypeError("Please Select an Award Type")
        validationResult = false
      } else {
        setAwardTypeError("")
      }
      if(isCustomType && campaign['award-type']  === ""){
        setAwardTypeError("")
        setCustomAwardTypeError("Please Enter a Custom Award Name")
        validationResult = false
      } else {
        setCustomAwardTypeError("")
      }
      if(campaign['name'] === ""){
        setCampaignNameError("Please Enter a Campaign Name")
        validationResult = false
      } else {
        setCampaignNameError("")
      }
      if(campaign['description'] === ""){
        setDescriptionError("Please Enter a Description")
        validationResult = false
      } else {
        setDescriptionError("")
      }
      if(campaign['owner'] === ""){
        setOwnerError("Please enter an owner alias")
        validationResult = false
      } else {
        setOwnerError("")
      }

      if(campaign['start-date'] === ""){
        setCampaignStartError("Please select a start date")
        validationResult = false
      } else {
        setCampaignStartError("")
      }
      if(campaign['end-date'] === ""){
        setCampaignEndError("Please select an end date")
        validationResult = false
      } else {
        setCampaignEndError("")
      }
      if(campaign['NomineeScope'].length==0){
        setNomineeScopeError("Please add at least one nominee scope")
        validationResult = false
      } else {
        setNomineeScopeError("")
      }
      if(campaign['NominatorScope'].length==0){
        setNominatorScopeError("Please add at least one nominator scope")
        validationResult = false
      } else {
        setNominatorScopeError("")
      }
      if(campaign['tasks'].length == 0){
        setFormError("Please add at least 1 Task")
        validationResult = false
      } else {
        setFormError("")
      }
      return(validationResult)
    }

    let validateNewTaskForm = () => {
      let validationResult = true;
      if(newTask['name']  === ""){
        setNewTaskNameError("Please Select a Task Type")
        validationResult = false
      } else {
        setNewTaskNameError("")
      }
      if(newTask['due']  === ""){
        setNewTaskDateError("Please Select the due date for the Task")
        validationResult = false
      } else {
        setNewTaskDateError("")
      }
      return(validationResult)
    }


    return (
        <div>
            <Form
                actions={
                  <SpaceBetween direction="horizontal" size="xs">
                      <Button 
                        variant="primary" 
                        onClick={() => {

                          if (validateForm()){
                            console.log(campaign)
                            onSave(campaign, props.showFlashbar);
                            setCampaign(emptyCampaign)
                            history.push("/campaigns/")
                          }
                            
                        }}>
                          {isEditMode ? "Edit Campaign" : "Create Campaign"}
                        </Button>
                  </SpaceBetween>
                }
                errorText={formError}
              >
    
            <SpaceBetween direction="vertical" size="l">
              
              <Container header={
                  <Header 
                    variant="h2">
                    New Campaign
                  </Header>
              }>
                <SpaceBetween direction="vertical" size="l">
                    <FormField label="Award Type" description="Select and Award Type for the Campaign" errorText={awardTypeError}>
                        <Select
                            options={awardTypes}
                            selectedOption={isCustomType ? findSelected("Custom", awardTypes) : findSelected(campaign['award-type'], awardTypes)}
                            onChange={(event) => {
                              if(event.detail.selectedOption.value === "Custom"){
                                console.log("custom award selected")
                                setCampaign({...campaign, ['award-type']: "", ['description']: ""})
                                setIsCustomType(true)
                              } else {
                                setIsCustomType(false)
                                setCampaign({...campaign, ['award-type']: event.detail.selectedOption.value, ['description']:event.detail.selectedOption.description})
                              }
                            }}
                            selectedAriaLabel="selected"
                        />
                    </FormField>
                    {
                      isCustomType &&
                      <FormField label="Custom Award Type" description="Enter a name for your custom award" errorText={customAwardTypeError}>
                        <Input 
                        placeholder="Custom Award Name"
                        value={campaign?.['award-type']}
                        onChange={({ detail } ) => {
                          //console.log(detail)
                          setCampaign({...campaign, ['award-type']: detail.value})
                        }}
                        >
                        
                        </Input>
                        
                      </FormField>
                    }
                    <FormField label="Campaign Name" description="Enter the name of the campaign" errorText={campaignNameError}>
                        <Input value={campaign.name} onChange={(event) => setCampaign({ ...campaign, "name": event.detail.value})} />
                    </FormField>
                    <FormField label="Description" description="" errorText={descriptionError}>
                        <TextArea value={campaign.description} onChange={(event) => setCampaign({...campaign, description: event.detail.value})} />
                    </FormField>
                    <FormField label="Owner" description="" errorText={ownerError}>
                    <Input disabled value={campaign.owner} onChange={(event) => setCampaign({...campaign, owner: event.detail.value})} />
                    </FormField>
                    <FormField label="Co-Owners" description="" errorText={coOwnerError}>
                    <Multiselect 
                      filteringType="manual"
                      placeholder="Search for Managers"
                      loadingText="Searching for Managers" 
                      statusType={searchingManagers? "loading" : "finished"}
                      onChange={(event) => {
                        //console.log(event.detail.selectedOptions)
                        setSelectedManagers(event.detail.selectedOptions)
                        let managerAliases =[]
                        event.detail.selectedOptions.forEach((x, i) => {
                          managerAliases.push(x.value)
                        })
                        setCampaign({...campaign, coowners: managerAliases})
                      }}
                      options={managers}
                      selectedOptions={selectedManagers}
                      onLoadItems={(e) => {
                        //console.log(e.detail.filteringText)
                        if(e.detail.filteringText.length > 2){
                          //console.log('searching for user')
                          getManagersWrapper(e.detail.filteringText)
                        } else {
                          setSearchingManagers(true)
                          setManagers([])
                        }
                      }}
                    />
                    </FormField>
                    <FormField label="Campaign Start" description="When does the campaign start?" errorText={campaignStartError}>
                        <DatePicker 
                                value={campaign['start-date']} 
                                onChange={(event) => setCampaign({ ...campaign, ["start-date"]: event.detail.value})} 
                                nextMonthAriaLabel="Next month"
                                placeholder="YYYY/MM/DD"
                                previousMonthAriaLabel="Previous month"
                                todayAriaLabel="Today"
                        />
                    </FormField>
                    <FormField label="Campaign" description="When does the campaign end?" errorText={campaignEndError}>
                    <DatePicker 
                                value={campaign['end-date']} 
                                onChange={(event) => setCampaign({ ...campaign, ["end-date"]: event.detail.value})} 
                                nextMonthAriaLabel="Next month"
                                placeholder="YYYY/MM/DD"
                                previousMonthAriaLabel="Previous month"
                                todayAriaLabel="Today"
                            />
                    </FormField>
                    <FormField label="Nominee Scope" description="Who can be nominated" errorText={nomineeScopeError}>
                    <Button 
                      variant="primary" 
                      iconName="add-plus"
                      onClick={() => {
                        setNomineeModalVisible(true)
                    }}
                    >
                      Add Nominee Scope
                    </Button>
                    <TokenGroup
                    items={nomineeScopeTokens}
                    onDismiss={({ detail: { itemIndex } }) => {
                      console.log("removing item " + itemIndex)
                      let newCampaign = campaign
                      newCampaign['NomineeScope'].splice(itemIndex, 1)
                      setCampaign(newCampaign)
                      setNomineeScopeTokens(getScopeTokens(newCampaign['NomineeScope']))
                      console.log(newCampaign)
                    }}
                    />
                    </FormField>
                    <FormField label="Nominator Scope" description="Who can nominate" errorText={nominatorScopeError}>
                    <Button 
                      variant="primary" 
                      iconName="add-plus"
                      onClick={() => {
                        setNominatorModalVisible(true)
                    }}
                    >
                        Add Nominator Scope
                    </Button>
                    <TokenGroup
                    items={nominatorScopeTokens}
                    onDismiss={({ detail: { itemIndex } }) => {
                      console.log("removing item " + itemIndex)
                      let newCampaign = campaign
                      newCampaign['NominatorScope'].splice(itemIndex, 1)
                      setCampaign(newCampaign)
                      setNominatorScopeTokens(getScopeTokens(newCampaign['NominatorScope']))
                      console.log(newCampaign)
                    }}
                    />
                    </FormField>


                </SpaceBetween>
              </Container>
            </SpaceBetween>
            <Table
                onSelectionChange={({ detail }) => {
                    setSelectedTasks(detail.selectedItems)
                    console.log(selectedTasks)
                }
                }
                selectedItems={selectedTasks}

                columnDefinitions={[
                    {
                        name: "name",
                        header: "Task",
                        cell: e => e.name,
                        sortingField: "name"
                    },
                    {
                        id: "due",
                        header: "Due",
                        cell: e => e.due,
                        sortingField: "alt"
                    }
                    
                ]}
                items={newTasks}
                loadingText="Loading resources"
                selectionType="multi"
                trackBy="id"
                empty={
                    <Box textAlign="center" color="inherit">
                    <b>No Tasks</b>
                    <Box
                        padding={{ bottom: "s" }}
                        variant="p"
                        color="inherit"
                    >
                       Click "Add Task" to add Tasks for a manager to complete at the end of the campaign.
                    </Box>
                    </Box>
                }
                header={
                    <Header
                    actions={
                      <div>
                        <Button 
                          variant="primary"
                          onClick={() => {
                              setModalVisible(true)
                          }}
                        >
                        Add Task
                      </Button>
                      <Button 
                          variant="secondary"
                          style={{alignItems:"flex-end"}}
                          disabled={selectedTasks.length == 0 ? true : false}
                          onClick={() => {
                            let updatedTasks = newTasks;
                            selectedTasks.forEach(task => {
                              console.log(task)
                              let index
                              newTasks.forEach((t, i) => {
                                if(t['id'] === task['id']){
                                  index = i
                                }
                              })
                              updatedTasks.splice(index, 1)
                              console.log(updatedTasks)
                            })
                            setCampaign({...campaign, ['tasks']: updatedTasks})
                            setNewTasks(updatedTasks)
                            setSelectedTasks([])

                          }}
                      >
                          Delete Task
                      </Button> 
                    </div>
                    }
                    r>
                    Manager Tasks
                    </Header>
                    
                }
                
                
                />
          </Form>
          <Modal
                onDismiss={() => {
                    setModalVisible(false)
                    setNewTask(emptyTask)
                }}
                visible={modalVisible}
                closeAriaLabel="Close modal"
                footer={
                    <Box float="right">
                    <SpaceBetween direction="horizontal" size="xs">
                        <Button variant="link"
                            onClick={() => {
                                setModalVisible(false)
                                setNewTask(emptyTask)
                                setNewTaskNameError("")
                                setNewTaskDateError("")
                            }}
                        >
                            Cancel
                        </Button>
                        <Button variant="primary"
                            onClick={()=>{
                              if (validateNewTaskForm()){
                                console.log(newTask)
                                let newTasksList = newTasks
                                newTask['id'] = newTasks.length
                                newTasksList.push(newTask)
                                setNewTasks(newTasksList)
                                setCampaign({...campaign, ['tasks']: newTasksList})
                                setModalVisible(false)
                                setNewTask(emptyTask)
                                console.log(campaign)
                              }
                                
                            }}
                        >Add</Button>
                    </SpaceBetween>
                    </Box>
                }
                header="New Task"
            >
                    <FormField label="Task Type" description="" errorText={newTaskNameError}>
                      <RadioGroup
                            value={newTask.name}
                            onChange={(event) =>{
                              console.log(event)
                              setNewTask({ ...newTask, "name": event.detail.value})
                            }}
                            items={taskTypes} 
                          
                          />
                    </FormField>
                    <FormField label="Due Date" description="" errorText={newTaskDateError}>
                        <DatePicker 
                            value={newTask.due} 
                            onChange={(event) => setNewTask({ ...newTask, "due": event.detail.value})} 
                            nextMonthAriaLabel="Next month"
                            placeholder="YYYY/MM/DD"
                            previousMonthAriaLabel="Previous month"
                            todayAriaLabel="Today"
                        />
                        
                    </FormField>
            </Modal>
            <ScopeModal
              title="New Nominee Scope"
              visible={nomineeModalVisible}
              closeModal={() => {setNomineeModalVisible(false)}}
              addScope={(scope) => {
                let newCampaign = campaign
                newCampaign['NomineeScope'].push(scope)
                setCampaign(newCampaign)
                setNomineeScopeTokens(getScopeTokens(newCampaign['NomineeScope']))
                console.log(newCampaign)
              }}
            />
            <ScopeModal
              title="New Nominator Scope"
              visible={nominatorModalVisible}
              closeModal={() => {setNominatorModalVisible(false)}}
              addScope={(scope) => {
                let newCampaign = campaign
                newCampaign['NominatorScope'].push(scope)
                setCampaign(newCampaign)
                setNominatorScopeTokens(getScopeTokens(newCampaign['NominatorScope']))
                console.log(newCampaign)
              }}
            />


        </div>
    )

}


export default NewCampaign