import { getAwardsByAlias } from "../Utils/API"

import { useState, useEffect } from "react";

import {
    Cards,
    Box,
    Button,
    Pagination,
    Header,
    ButtonDropdown
  }
  from "@awsui/components-react";

let alias =  sessionStorage.getItem('alias')

let Awards = () => {
    const [awards, setAwards] = useState([])
    const [loading, setLoading] = useState(true);
    const [selectedAwards, setSelectedAwards] = useState([]);

    useEffect(() => {

        getAwardsByAlias(alias).then(
            (result) => {
                //console.log(result)
                setAwards(result.Items)
                setLoading(false)
            },
            (error) => {
                console.log(error);
                setLoading(false);
            }
        );
     }, []);

    return (

        
        <div>
           <Cards
                loading={loading}

                ariaLabels={{
                    itemSelectionLabel: (e, t) => `select ${t.name}`,
                    selectionGroupLabel: "Item selection"
                }}
                cardDefinition={{
                    header: e => e['award-type']+' '+e['campaign-name'],                

                    sections: [
                        
                    {
                        id: "photo",
                        width: "50",
                        content: (item) => <div style={{minHeight:'160px'}} >
                            {
                                <div >
                                <img 
                                    alt={item.alias}
                                    height="160px" 
                                    style={{border: "1px solid #aaa"}} 
                                    src={"https://internal-cdn.amazon.com/badgephotos.amazon.com/?uid="+item.alias}
                                >
                                </img>
                                
                                </div>
                            }   
                            <div style={{fontWeight: "bold"}}>{item.alias}</div>

                            </div>
                    },
                    {
                        id: "award-type",
                        header: "Award Type",
                        content: (item) => (
                        <div style={{display: 'flex', justifyContent: 'space-between'}}>
                            <div>{item['award-type']}</div>
                            <ButtonDropdown
                                items={[
                                    {
                                        text: "Details",
                                        id: "details",
                                        disabled: false
                                    }
                                    ]}
                                    ariaLabel="Control instance"
                                    variant="icon"
                                >
                                    Test
                            </ButtonDropdown>
                        </div>

                        )
                    },
                    {
                        id: "timestamp",
                        header: "Date Awarded",
                        content: e => e.timestamp.substring(0,10)
                    },
                    
                    ]
                }}
                cardsPerRow={[
                    { cards: 1 },
                    { minWidth: 200, cards: 3 }
                ]}
                items={awards}
                loadingText="Loading Awards"
                trackBy="id"
                visibleSections={["award-type2", "photo", "award-type", "timestamp", "campaign-name" ]}
                empty={
                    <Box textAlign="center" color="inherit">
                    <b>No Awards</b>
                    <Box
                        padding={{ bottom: "s" }}
                        variant="p"
                        color="inherit"
                    >
                        No Awards to display.
                    </Box>
                    </Box>
                }
                header={
                    <Header>
                        My Awards
                    </Header>
                }

            />
        </div>
    )
}

export default Awards;