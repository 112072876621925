import {
    Box,
    TextFilter,
    Button,
    Pagination,
    CollectionPreferences,
    Header,
    Table,
    Grid,
    StatusIndicator,
    Link,
    SpaceBetween,
    Icon,
    Modal,
    FormField,
    Textarea,
    Select,
    
  }
  from "@awsui/components-react";

import { 
    addAward
} from "../Utils/API"   

  import { useEffect, useState } from "react";

let WinnerModal = (props) => {


    let saveWinner = (nomination) => {
        //console.log("Submiting Campaign")
        //console.log(nomination)
        addAward(nomination).then(
            (result) => {
                //console.log(result)
                window.location.reload();
            },
            (error) => {
                console.log(error)
            }
        )    
    }

    return (
        <Modal
                visible={props.isVisible}
                onDismiss={() => {
                    props.setIsVisible(false)
                }}
                header={"Confirm Winner"}
            >
                <SpaceBetween direction="vertical" size="xl" >
                    <div> Make <b>{props.nomination['name']}</b> a winner for this campaign</div>
                    <div><i>Note: Once a winner is selected, this operation cannot be undone. Multiple winners can be selected per campaign until the campaign is marked as complete.</i></div>
                  <Button
                    variant="primary"
                    onClick={(event) => {
                        console.log(props.nomination)
                        saveWinner(props.nomination)
                        props.setIsVisible(false)
                    }}
                  >
                    Make Winner
                  </Button>
                </SpaceBetween>
            </Modal>
    )

}

export default WinnerModal;