import './App.css';
import {
  BrowserRouter as Router,
  Switch,
  Route
}
from "react-router-dom";
import {
  Link
}
from "@awsui/components-react";

import Feedback from './pages/Feedback'

import Register from './components/Register';
import Login from './components/Login';
import SideNavigation from "@awsui/components-react/side-navigation";
import AppLayout from "@awsui/components-react/app-layout";
import Flashbar from "@awsui/components-react/flashbar";
import TopNavigation from "./components/TopNavigation";
import Awards from "./components/Awards";
import Home from "./components/Home";
import NominationEdit from "./components/NominationEdit";
import MyTasks from "./components/MyTasks";
import MyCampaigns from "./components/MyCampaigns";
import MyOldCampaigns from "./components/MyOldCampaigns";
import NewCampaign from "./components/NewCampaign"
import RecentCampaigns from "./components/RecentCampaigns"
import CampaignsView from './components/CampaignsView';
import AwardsCatalog from './components/AwardsCatalog'
import ReviewCampaign from './components/ReviewCampaign';

import getMidwayToken from "./components/MidwayToken";
import { useState, createContext, useContext } from 'react';

import { getUserDetails } from "./Utils/API"

import { devLogger } from './Utils/Logging';

const midwayToken = getMidwayToken();
let alias = '';
if (midwayToken && midwayToken.payload && midwayToken.payload.sub) {
  console.log("***VERSION***")
  console.log("0.1.0")
  alias = midwayToken.payload.sub;
  devLogger(midwayToken.payload)
  devLogger(alias)
  //alias = 'skarra';
  sessionStorage.setItem("alias", alias);

}



function App() {
  const [ flashbarItems, setFlashbarItems ] = useState([])
  const [ isManager, setIsManager ] = useState(false)
  const [ isDelegate, setIsDelegate ] = useState(false)


  getUserDetails(alias).then(
    (result) => {
        //console.log(result)
        //isManager = result['isManager']
        sessionStorage.setItem("isManager", result['isManager']);
        sessionStorage.setItem("isDelegate", result['isDelegate']);
        setIsManager(result['isManager'])
        setIsDelegate(result['isDelegate'])
        //console.log(result)
    },
    (error) => {
        console.log("error")
        console.log(error);
    }
  );

  let randomInteger = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  //setIsManager(isManager)

  let showFlashbar = (type, header, message, link) => {
    //console.log("Invoke Flashbar")
    //setFlashbarVisible(false)
   let msgId = String(randomInteger(1, 1000))
    setFlashbarItems(flashbarItems => [...flashbarItems,
      {
        type: type,
        header: header,
        content: (
          <>
            {message}
            {" "}
            <Link color="inverted">
            {link}
          </Link>
          </>
        ),
        dismissible: true,
        dismissLabel: "Dismiss message",
        onDismiss: () => {
          setFlashbarItems(flashbarItems =>
            flashbarItems.filter(item => item.id !== msgId)
          )
        },
        id: msgId
      }
    ]
      
    )
    //console.log(flashbarItems)
  }

  
  if(sessionStorage.getItem('alias') == '' || sessionStorage.getItem('alias') == null){
    //sideNavItems = []
  }

  let sideNavItems = 
  [
    {/* type: "link", text: "All items", href: "/list".*/},
    { type: "link", text: "Nominate", href: "/eligible-campaigns/"},
    { type: "divider" },
    { type: "link", text: "My Awards", href: "/awards/"},
    { type: "divider" },
    { type: "link", text: "Feedback", href: "/feedback"}
  ]

  let mgrSideNavItems = 
  [
    {/* type: "link", text: "All items", href: "/list".*/},
    { type: "link", text: "Nominate", href: "/eligible-campaigns/"},
    { type: "divider" },
    { type: "link", text: "My Awards", href: "/awards/"},
    { type: "divider" },
    { type: "link", text: "My Tasks", href: "/tasks/"},
    { type: "divider" },
    { type: "link", text: "My Campaigns", href: "/campaigns/"},
    { type: "divider" },
    { type: "link", text: "Catalog", href: "/catalog/"},
    { type: "divider" },
    { type: "link", text: "Feedback", href: "/feedback"}
  ]
  //console.log(isManager)
  if(isManager || isDelegate){
    sideNavItems = mgrSideNavItems
  }


  return (
    <Router>
      <div id="h" style={{ position: "sticky", top: 0, zIndex: 1002 }}>
        <TopNavigation alias={alias} />     
      </div>

      <AppLayout
          navigation={
            <div id="s" style={{ marginTop: "100px"}}>
              <SideNavigation items={sideNavItems}/>
            </div>
          }
          headerSelector='#h'
          notifications={<Flashbar items={flashbarItems} />}
          stickyNotifications
        content={
          <Switch>
            <Route path="/campaigns/:id">
              <CampaignsView showFlashbar={showFlashbar} />
            </Route>
            <Route path="/register">
              <Register />
            </Route>
            <Route path="/feedback">
              <Feedback showFlashbar={showFlashbar}/>
            </Route>
            <Route path="/login">
              <Login />
            </Route>
            <Route path="/awards/">
              <Awards />
            </Route>
            <Route path="/eligible-campaigns/">
              <RecentCampaigns />
            </Route>
            <Route path="/tasks/">
              <MyTasks />
            </Route >
            <Route path="/campaigns/">
              <MyCampaigns />
            </Route >
            <Route path="/nomination/new/:id">
              <NominationEdit showFlashbar={showFlashbar}/>
            </Route>
            <Route path="/nomination/:id">
              <NominationEdit showFlashbar={showFlashbar}/>
            </Route>
            <Route path="/campaign/new/:type">
              <NewCampaign showFlashbar={showFlashbar}/>
            </Route>
            <Route path="/campaign/new">
              <NewCampaign showFlashbar={showFlashbar}/>
            </Route>
            <Route path="/catalog/">
              <AwardsCatalog />
            </Route>
            <Route path="/review/">
              <ReviewCampaign />
            </Route>
            <Route path="/home">
              <Home isManager={isManager}/>
            </Route>
            <Route path="/">
              <Login />
            </Route >
            
          </Switch>

      }
    />

    </Router>
  );
}

export default App;
