import { useParams } from "react-router-dom";
import ReportsAwards from "./ReportsAwards"



let MyTasks = () => {
    const { alias } = useParams();

    return (
        <div>
            <ReportsAwards />
        </div>
    )

}


export default MyTasks;