
import "./ReviewCampaign.css"

import { useEffect, useState } from "react";

import {
    Box,
    TextFilter,
    Button,
    Pagination,
    CollectionPreferences,
    Header,
    Table,
    Grid,
    StatusIndicator,
    Link,
    SpaceBetween,
    Icon,
    Modal,
    Select,
    ColumnLayout
    
  }
  from "@awsui/components-react";

  import { useHistory } from "react-router-dom";

  import { 
    getNominations,
    getUserDetails,
    editCampaign,
    getCampaignsByOwner,
    getManagersByCampaign,
    exportNominationsByCampaign
} from "../Utils/API"
import EndorsementsModal from "./EndorsementsModal";
import VoteModal from "./VoteModal";
import WinnerModal from "./WinnerModal";
import NominationModal from "./NominationModal";

let ReviewCampaign = (props) => {

    let history = useHistory();

    const [ nominations, setNominations ] = useState([])
    const [ filteredNominations, setFilteredNominations ] = useState([])
    const [ nominationsCount, setNominationsCount ] = useState(0)
    const [ managerFilterSelection, setManagerFilterSelection ] = useState("")
    const [ isNominationsTableLoading, setIsNominationsTableLoading ] = useState(false)
    const [ nominationTableSelectedOption, setNominationTableSelectedOption ] = useState()

    const [ showEndorsementsModal, setShowEndorsementsModal ] = useState(false)
    const [ endorsementsModalNomination, setEndorsementsModalNomination ] = useState({})

    const [ isVoteModalVisible, setIsVoteModalVisible ] = useState(false);
    const [ voteModalNomination, setVoteModalNomination ] = useState({})

    const [ isNominationModalVisible, setIsNominationModalVisible ] = useState(false);
    const [ nominationModalNomination, setNominationModalNomination ] = useState({})

    const [ isWinnerModalVisible, setIsWinnerModalVisible ] = useState(false);
    const [ winnerModalNomination, setWinnerModalNomination ] = useState({})
    const [ campaignId, setCampaignId ] = useState(props?.campaignId)

    const [campaigns, setCampaigns] = useState([])
    const [ campaignFilterSelection, setCampaignFilterSelection ] = useState({})

    const [managers, setManagers] = useState([])

    let alias =  sessionStorage.getItem('alias')


    let getNominationsWrapper = async(campaignId) => {
        setIsNominationsTableLoading(true)
        getNominations(campaignId,).then(
            async (result) => {
                //console.log(result)
                result = result['Items']
                for await (const nom of result) {
                    await getUserDetails(nom['nominee-alias']).then(
                        async (user) => {
                            //console.log(user)
                            nom['name'] = user['name'];
                            nom['manager-alias'] = user['manager'];
                            await getUserDetails(nom['manager-alias']).then(
                                (mgr) => {
                                    nom['manager-name'] = mgr?.['name']
                                },
                                (error) => {

                                }
                            )
                        },
                        (error) => {

                        }
                    );
                }
                setNominations(result)
                setFilteredNominations(result)
                setNominationsCount(result.length)
                setIsNominationsTableLoading(false)
            },
            (error) => {
                console.log(error)
                setIsNominationsTableLoading(false)
            }
        )
    }

    let filterNominationsByManager = ( managerAlias ) => {
        if(managerAlias == "none"){
            setFilteredNominations(nominations);
        } else {
            //console.log("else")
           let newNominations = nominations.filter(nomination => nomination['manager-alias'] == managerAlias)
           setFilteredNominations(newNominations)
        }
    }    

    useEffect(() => {
        getNominationsWrapper(campaignId)
        getManagersByCampaign(campaignId).then(
            (result) => {
                //console.log(result)
                let labeledMangers = [{label: "-", value: "none"}];
                result.forEach((m) => {
                    m['label'] = m['name'];
                    m['value'] = m['alias'];
                    labeledMangers.push(m);
                })
                setManagers(labeledMangers)
            },
            (error) => {
                console.log(error)
            }
        )
        getCampaignsByOwner(alias).then(
            (result) => {
                //console.log(result)
                //const res = [];
                if(result != "No Campaigns Found"){
                    //This block filters for active campaigns
                    /* result.forEach(element => {
                        if(element.status === 'open' || element.status === 'pending'){
                            res.push(element)
                        }
                    }) */
                    result.sort((a,b) => a['timestamp'] > b['timestamp'] ? 1 : -1);
                    //console.log(result)
                    let labeledCampaigns = [];
                    result.forEach((c) => {
                        c['label'] = c['name'];
                        labeledCampaigns.push(c)
                    })
                    //console.log(labeledCampaigns)
                    setCampaigns(labeledCampaigns)
                }
                
                //setLoading(false)
            },
            (error) => {
                console.log(error);
                //setLoading(false);
            }
        );
     }, []);

     let sortby = (type) => {
        let sortedNominations = filteredNominations;
        if (type === "votes"){
           sortedNominations = filteredNominations.sort((a,b) => {
                return a?.['votes']?.length > b?.['votes']?.length ? -1 : 1
            })
        }
        setFilteredNominations(sortedNominations)
     }

     let checkIsVoteDisabled = (nomination) => {
        //console.log(nomination)
        let isDisabled = false;
        if(nomination != undefined){
            nomination?.['votes']?.forEach( (vote) => {
                //console.log(vote['alias'])
                if(vote?.['alias'] == alias){
                    
                    isDisabled = true;
                }
            })
        } else {
            isDisabled = true;
        }
        return isDisabled
     }


    return (
        <div>
            <Table 
                columnDefinitions={[
                    {
                        id: "manager",
                        header: "Manager",
                        cell: item => { return (<Link target="_blank" href={"https://phonetool.amazon.com/users/" + item['manager-alias']}>{item?.['manager-name'] + " (" + item?.['manager-alias'] + ")"}</Link>) },
                        isRowHeader: true
                    },
                    {
                        id: "nominee",
                        header: "Nominee",
                        cell: item => { return (<Link target="_blank" href={"https://phonetool.amazon.com/users/" + item['nominee-alias']}>{item['name'] + " (" +  item['nominee-alias'] + ")" }</Link>) },
                    },
                    {
                        id: "nominator",
                        header: "nominator",
                        cell: item => item['nominator-alias'],
                    },
                    {
                        id: "impact",
                        header: "Impact",
                        cell: item =>  {return (<a onClick={(event) => {event.preventDefault(); setIsNominationModalVisible(true); setNominationModalNomination(item)}}>{ item?.impact }</a>)},
                        isRowHeader: false
                    },
                    {
                        id: "endorsements",
                        header: "Endorsements",
                        cell: item => {return (<a onClick={(event) => {event.preventDefault(); setShowEndorsementsModal(true); setEndorsementsModalNomination(item)}}>{ item?.endorsements?.length }</a>)},
                        isRowHeader: true,
                        sortingField: "endorsements"
                    },
                    {
                        id: "votes",
                        header: "Votes",
                        cell: item => item?.votes?.length,
                        isRowHeader: true,
                        sortingField: "votes",
                        sortingDecending: true
                    }
                ]}
                onSortingChange={({ detail }) => {
                    console.log(detail)
                    sortby(detail['sortingColumn']['id'])

                }}
                items={filteredNominations}
                selectionType="single"
                wrapLines
                resizableColumns
                selectedItems={nominationTableSelectedOption}
                onSelectionChange={({ detail }) => {
                    setNominationTableSelectedOption(detail.selectedItems)
                    console.log(detail.selectedItems)
                }}
                loading={isNominationsTableLoading}
                header={
                    <Header 
                        actions={
                            <SpaceBetween
                                direction="horizontal"
                                size="xs"
                            >
                                { props.campaign?.['status'] !== "complete" &&
                                    <Button
                                        variant="primary"
                                        disabled={nominationTableSelectedOption?.length > 0 ? false : true}
                                        onClick={(event) => {
                                            setWinnerModalNomination(nominationTableSelectedOption[0])
                                            setIsWinnerModalVisible(true)
                                        }}
                                    >
                                        Make Winner
                                    </Button>
                                }
                                { props.campaign?.['status'] !== "complete" &&
                                    <Button
                                        onClick={(event) => {
                                            setVoteModalNomination(nominationTableSelectedOption[0])
                                            setIsVoteModalVisible(true)
                                        }}
                                        disabled={checkIsVoteDisabled(nominationTableSelectedOption?.[0])}
                                    >
                                        Vote
                                    </Button>
                                }
                                    <Button
                                        onClick={(event) => exportNominationsByCampaign(campaignId).then(
                                            (result) => {
                                                //console.log(result)
                                                const link = document.createElement('a');
                                                //link.download = "export";
                                                link.href = result;
                                                link.click();
                                            },
                                            (error) => {
                                                console.log(error)
                                            }
                                        )}
                                    >
                                        Export
                                    </Button>
                            </SpaceBetween>
                        }
                    >
                        Nominations ({nominationsCount})
                    </Header>
                }
                filter={
                    <div style={{maxWidth: "40%" }}>
                        <ColumnLayout columns={2}>
                        <Select 
                        placeholder="Campaign"
                            selectedOption={""}
                            options={campaigns}
                            onChange={({ detail }) => {
                                //history.push
                                let id = detail.selectedOption['id'];
                                history.push("/campaigns/" + id)
                                setCampaignId(id)
                                setCampaignFilterSelection(id)
                                window.location.reload();
                                //setManagerFilterSelection(detail.selectedOption)
                                //filterNominationsByManager(detail.selectedOption.value)
                            }}
                            expandToViewport={false}
                        />
                        <Select
                            placeholder="Manager" 
                            selectedOption={managerFilterSelection}
                            options={managers}
                            onChange={({ detail }) => {
                                setManagerFilterSelection(detail.selectedOption)
                                filterNominationsByManager(detail.selectedOption.value)
                            }}
                            expandToViewport={false}
                        />
                        </ColumnLayout>
                    </div>
                }
            />
            <EndorsementsModal visible={showEndorsementsModal} setShowEndorsementsModal={setShowEndorsementsModal} nomination={endorsementsModalNomination} />
            <VoteModal isVisible={isVoteModalVisible} nomination={voteModalNomination} setIsVisible={setIsVoteModalVisible}/>
            <WinnerModal isVisible={isWinnerModalVisible} nomination={winnerModalNomination} setIsVisible={setIsWinnerModalVisible}/>
            <NominationModal isVisible={isNominationModalVisible} nomination={nominationModalNomination} setIsVisible={setIsNominationModalVisible}/>
        </div>
    )

}

export default ReviewCampaign;