import { 
    getCampaignsByOwner,
    getNominations,
    addAward,
    editCampaign 
} from "../Utils/API"

import { useState, useEffect, useHistory } from "react";

import {
    Cards,
    Box,
    TextFilter,
    Button,
    Pagination,
    CollectionPreferences,
    Header,
    Table,
    Grid,
    StatusIndicator,
    Link,
    SpaceBetween,
    Icon,
    Modal
  }
  from "@awsui/components-react";

let alias =  sessionStorage.getItem('alias')


let saveWinner = (nomination) => {
    //console.log("Submiting Campaign")
    //console.log(nomination)
    nomination = nomination[0]
    addAward(nomination).then(
        (result) => {
            //console.log(result)
            window.location.reload();
        },
        (error) => {
            console.log(error)
        }
    )    
}

let completeCampaign  = (campaign) => {
    //console.log("Completing the campaign")
    campaign['status'] = 'closed'
    //console.log(campaign)
    editCampaign(campaign).then(
        (result) => {
            //console.log(result)
            window.location.reload();
        },
        (error) => {
            console.log(error)
        }
    ) 
}

let MyCampaigns = () => {

    const [campaigns, setCampaigns] = useState([])
    const [nominations, setNominations] = useState([])
    const [loading, setLoading] = useState(true);
    const [nomLoading, setNomLoading ] = useState(false)
    const [selectedCampaigns, setSelectedCampaigns] = useState([]);
    const [selectedNominations, setSelectedNominations] = useState([]);
    const [ showVotesModal, setShowVotesModal] = useState(false)

    useEffect(() => {

        getCampaignsByOwner(alias).then(
                (result) => {
                    //console.log(result)
                    //const res = [];
                    if(result != "No Campaigns Found"){
                        //This block filters for active campaigns
                        /* result.forEach(element => {
                            if(element.status === 'open' || element.status === 'pending'){
                                res.push(element)
                            }
                        }) */
                        result.sort((a,b) => a['timestamp'] > b['timestamp'] ? 1 : -1);
                        setCampaigns(result)
                    }
                    
                    setLoading(false)
                },
                (error) => {
                    console.log(error);
                    setLoading(false);
                }
            );
         }, []);

    let getNoms = (campaignId, campaign) => {
        setNomLoading(true)
        getNominations(campaignId,).then(
            (result) => {
                //console.log("nominations")
                //console.log(result.Items)
                let res = []
                if(result.Items.length != 0){
                    result.Items.forEach((x, i) => {
                        //console.log(campaign['winner'])
                        //console.log(x['nominee-alias'])
                        if(!campaign['winner'].includes(x['nominee-alias']) ){
                            //console.log(x)
                            res.push(x)
                        }
                        
                    })
                }
                setNominations(res)
                setNomLoading(false)
            },
            (error) => {
                console.log(error)
                setNomLoading(false)
            }
        )
        //console.log(awardId)

    }

    let statusMapping = {
        "closed": "pending",
        "open": "success",
        "complete": "stopped"
    }

    return (
        <div>
            <Cards
                loading={loading}
                selectionType="single"
                onSelectionChange={({ detail }) =>{
                    setSelectedCampaigns(detail.selectedItems)
                    //console.log(detail.selectedItems)
                    getNoms(detail.selectedItems[0].id, detail.selectedItems[0])
                }
                    
                }
                selectedItems={selectedCampaigns}
                ariaLabels={{
                    itemSelectionLabel: (e, t) => `select ${t.name}`,
                    selectionGroupLabel: "Item selection"
                }}
                cardDefinition={{
                    header: (e) => <Link  variant="primary" fontSize="heading-l" href={"/campaigns/" + e.id} >{e.name}</Link>,
                    sections: [
                    {
                        id: "campaign-name",
                        header: "Campaign Name",
                        content: e => e['campaign-name']
                    },
                    {
                        id: "award-type",
                        header: "Award Type",
                        content: e => e['award-type']
                    },
                    {
                        id: "start",
                        header: "Start Date",
                        content: e => e['start-date']
                    },
                    {
                        id: "end",
                        header: "End Date",
                        content: e => e['end-date']
                    },
                    {
                        id: "status",
                        header: "Status",
                        content: (e) => <StatusIndicator type={statusMapping[e['status']]}>{e['status']}</StatusIndicator>
                        
                    },
                    {
                        id: "winner",
                        header: "Winner",
                        content: e => e.winner.toString()
                    }
                
                    ]
                }}
                cardsPerRow={[
                    { cards: 1 },
                    { minWidth: 200, cards: 3 }
                ]}
                items={campaigns}
                loadingText="Loading Campaigns"
                trackBy="id"
                visibleSections={["award-type", "campaign-name", "start", "end", "status", "winner" ]}
                empty={
                    <Box textAlign="center" color="inherit">
                    <b>No Campaigns</b>
                    <Box
                        padding={{ bottom: "s" }}
                        variant="p"
                        color="inherit"
                    >
                        No campaigns to display.
                    </Box>
                    </Box>
                }
                header={

                    
                        <Header
                            actions={
                                <SpaceBetween
                                    direction="horizontal"
                                    size="xs"
                                >
                                    <Button
                                        variant="primary"
                                        style={{alignItems:"flex-end"}}
                                        href="/campaign/new"
                                    >
                                        New Campaign
                                    </Button>

                                </SpaceBetween>
                            }
                        >
                         My Campaigns
                        </Header> 
                        
                    
                }


            />
        </div>
                
    )

}

export default MyCampaigns;