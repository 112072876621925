import React, { useState, useEffect } from "react";
import { TopNavigation } from "@awsui/components-react";
import "../styles/topNavigation.css";
//import { getUser } from "./API";

let alias = sessionStorage.getItem('alias')

const STAGE = process.env.REACT_APP_STAGE

let stageText =""

if(STAGE === 'DEV') {
  stageText = " - Dev"
}

export default function TopNav(props) {
  

  //const alias = sessionStorage.getItem("alias");
  //const [name, setName] = useState(alias);

  //getUserName(alias)

  let title = "Amazon Nominate";
  let className = "";

  /*async function getUserName(alias) {
    let user = await getUser(alias)
    let name = alias
  
    if (user.Item !== undefined) {
      name = user.Item.name
    }
    setName(name)
  }
*/

  return (
    <TopNavigation
      className={`${className}`}
      identity={{
        href: "/home",
        title: `${title} (Beta) ${stageText}`,
        logo: {
          src: "/nominate-logo.svg",
          alt: "Nominate",
        },
      }}
      utilities={[
        {
          type: "button",
          text: `${props.alias}`,
          href: `/author/`,
          iconName: "user-profile",
          external: false,
          externalIconAriaLabel: " (opens in a new tab)",
        },
      ]}
      i18nStrings={{
        searchIconAriaLabel: "Search",
        searchDismissIconAriaLabel: "Close search",
        overflowMenuTriggerText: "More",
        overflowMenuTitleText: "All",
        overflowMenuBackIconAriaLabel: "Back",
        overflowMenuDismissIconAriaLabel: "Close menu",
      }}
    />
  );
}
