import {
    Container,
    Box,
    Grid,
    SpaceBetween,
    Button,
    Header,
    
  }
  from "@awsui/components-react";
  import { useState, useEffect } from "react";
  import "../styles/home.css";

  import { getNominations } from "../Utils/API"

  import RecentCampaigns from "./RecentCampaigns"
  import { useHistory } from "react-router-dom";

  let diagram = require("../assets/nominate-diagram.png")
  let imgCreate = require("../assets/create.jpg")
  let imgNominate = require("../assets/nominate.jpg")
  let imgAward = require("../assets/award.jpg")

  
  // eslint-disable-next-line
  export default (props) => {
    let history = useHistory();
    let alias = sessionStorage.getItem('alias')
    //console.log(alias)
    if(alias == null || alias === ''){
      console.log('user not logged in')
      history.push('/login')
    }
    //console.log(props.isManager)

    return (
      <div>
      
      <div className="custom-home__header">
        <Box padding={{ vertical: "xxxl", horizontal: "s" }}>
          <Grid
            gridDefinition={[
              { offset: { l: 2, xxs: 1 }, colspan: { l: 8, xxs: 10 } },
              {
                colspan: { xl: 6, l: 5, s: 6, xxs: 10 },
                offset: { l: 2, xxs: 1 },
              },
              {
                colspan: { xl: 2, l: 3, s: 4, xxs: 10 },
                offset: { s: 0, xxs: 1 },
              },
            ]}
          >


            <div className="custom-home__header-title">
              <Box
                fontWeight="light"
                padding={{ bottom: "s" }}
                fontSize="display-l"
                color="inherit"
              >
                Amazon Nominate 
                <br />
                <span className="downSize">Transparency, Accountability and Timely Recognition!</span>
              </Box>
              <Box variant="p" fontWeight="light">
                
                <br/>
                <span className="custom-home__header-sub-title">
                  Create custom awards and nominate your peers and stakeholders for the awards they deserve!! Amazon Nominate provides ICs and Managers a single platform to run campaigns and solicit nominitations for awards.
                </span>

                <span className="custom-home__header-sub-title">
                </span>
              </Box>
              
            </div>
                <Button href="/eligible-campaigns/" variant="primary">
                  Get started
                </Button>
            
          </Grid>
          </Box>
        </div>
        <div>
          <Container
            header={
              <Header variant="h1"
              >
              How it Works
            </Header>
            }
            fitHeight
          >
          <div className="home-imgs">
            <div className="home-img-div">
              <img src={imgCreate.default} alt="Diagram" width={150} height={100} />
              <p className="home-img-header">Create:</p>
              <p>It all starts with a campaign. Campaigns are calls for nominations with a specific start and end date. Campaigns can be named and scoped to a org. For example, you can create a campaign that is available only to your org (everyone that reports up to your alias) for a quarterly Bias for Action Award. </p>
              {
                props.isManager && <p><a href="/campaign/new">Create Campaign</a></p>
              }
              
            </div>
            <div className="home-img-div">
              <img src={imgNominate.default} alt="Diagram" width={150} height={100} />
              <p className="home-img-header">Nominate:</p>
              <p>You can specifify who is allowed to nominate (nominator), ICs or Managers or Both. You can also specify who is allowed to be nominated (nominee), ICs or Managers or Both. Once created the campaign will be made visible to all that fall within the defined scope. They can then submit nominations for the campaign.</p>
            </div>
            <div className="home-img-div">
              <img src={imgAward.default} alt="Diagram" width={150} height={100} />
              <p className="home-img-header">Award:</p>
              <p>Once a campaign ends, the owner of the campaign can select the winner(s) of the campaign. They will get a notification that they have won and the winner's manager will receive the tasks that are associated with that award (e.g. give a gift card).</p>
            </div>
          </div>
          <div style={{height: "200px"}}>

          </div>
          </Container>


          
        </div>
        <div>
          <Header variant="h1">
              
          </Header>
          
        </div>
      </div>
    );
  }
  