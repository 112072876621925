
import {
    Button,
    CollectionPreferences,
    Container,
    Form,
    FormField,
    Header,
    Input,
    Select,
    SpaceBetween,
    Textarea as TextArea,
  }
  from "@awsui/components-react";
  import { useState, useEffect } from "react";

  import { useHistory } from "react-router-dom";
  import { addFeedback } from "../Utils/API"
  import { getCampaings, getEligibleUsers } from "../Utils/API"


  function findSelected(inItem, items) {
    if (inItem) {
      for (let i in items) {
        //console.log(items[i])
        if (items[i].id === inItem){
            return { value: items[i].value, label: items[i].label, id: items[i].id, type: items[i].type }
        }
      }
      console.log('findSelected not found', inItem);
    }
    return null;
  }


  
  let alias = sessionStorage.getItem('alias')

  let emptyFeedback = {
    "alias": alias,
    "priority": "",
    "feedback": ""
  }

  let priorityOptions = [
    {
      "label": "P1 - Critical Blocker", 
      "value": "P1 - Critical Blocker",
    },
    {
      "label": "P2 - Important", 
      "value": "P2 - Important",
    },
    {
      "label": "P3 - Desirable", 
      "value": "P3 - Desirable",
    }
  ]
let Feedback = (props) => {
    let history = useHistory();
    const [ feedbackObj, setFeedbackObj ] = useState(emptyFeedback);
    const [ formErrorText, setFormErrorText ] = useState("")
    const [ validForm, setValidForm ] = useState(false)
    const [ priorityError, setPriorityError ] = useState("")
    const [ feedbackError, setFeedbackError ] = useState("")

    let validateForm = () => {
      let validationResult = true
      if(feedbackObj['feedback'] === ""){
        setFeedbackError("Please Enter Valid Feedback")
        validationResult = false
      } else {
        setFeedbackError("")
      }
      if (!feedbackObj['priority']) {
        setPriorityError( "Please Select a Priority")
        validationResult = false
      } else {
        setPriorityError("")
      }
      if(!validationResult){
        setFormErrorText("Please Complete All Required Fields")
      } else {
        setFormErrorText("")
      }
      return(validationResult)
    }


    function findSelected(inItem, items) {
      if (inItem) {
        for (let i in items) {
          //console.log(items[i])
          if (items[i].value === inItem){
              return { value: items[i].value, label: items[i].label}
          }
        }
        console.log('findSelected not found', inItem);
      }
      return null;
    }

    let onSave = (feedback, showFlashbar) => {
      
        console.log("Submiting Feedback")
      //console.log(nomination)
      addFeedback(feedback).then(
          (result) => {
              //console.log(result)
              showFlashbar(
                "success", 
                "Feedback Successfully Submitted", 
                "Thank You!",
                ""
              )
          },
          (error) => {
              //console.log(error)
              showFlashbar(
                "error", 
                "Unable to Submit Feedback", 
                String(error),
                ""
              )
          }
      )
      
    }
  

    return (
        <div>
            <Form
                actions={
                  <SpaceBetween direction="horizontal" size="xs">
                      <Button 
                        variant="primary" 
                        onClick={() => {
                           //console.log(feedbackObj)
                            if (validateForm()){
                              onSave(feedbackObj, props.showFlashbar)
                              setFeedbackObj(emptyFeedback)
                              history.push("/")
                            }
                        }}>
                          Submit Feedback
                        </Button>
                  </SpaceBetween>
                }
                errorText={formErrorText}
              >
    
            <SpaceBetween direction="vertical" size="l">
              <Container header={
                  <Header 
                    variant="h2">
                    Submit Feedback
                  </Header>
              }>
                <SpaceBetween direction="vertical" size="l">
                    <FormField label="Priority" description="What is the priority of this request" errorText={priorityError}>
                        <Select
                            options={priorityOptions}
                            selectedOption={findSelected(feedbackObj['priority'], priorityOptions)}
                            onChange={(event) => {
                              setFeedbackObj({...feedbackObj, 'priority': event.detail.selectedOption.value})
                            }
                            }
                            selectedAriaLabel="selected"
                        />
                    </FormField>
                    <FormField label="Feedback" description="Please provide your feedback" errorText={feedbackError}>
                        <TextArea 
                          value={feedbackObj.feedback} 
                          onChange={(event) => {
                            setFeedbackObj({...feedbackObj, feedback: event.detail.value}) 
                          }
                          }
                        />
                    </FormField>
                </SpaceBetween>
              </Container>
            </SpaceBetween>
          </Form>


        </div>
    )
}

export default Feedback;