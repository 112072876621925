import {
    Box,
    Button,
    Container,
    ColumnLayout,
    Header,
    Link,
    SpaceBetween,
    StatusIndicator,
    Textarea as TextArea,
    ButtonDropdown
  }
  from "@awsui/components-react";
import { useEffect, useState } from "react";

import { useParams, useHistory, useLocation } from "react-router-dom";

import { getCampaignsById, editCampaign } from "../Utils/API"
import ReviewCampaign from "./ReviewCampaign";


let CampaignsView = (props) => {
    let alias =  sessionStorage.getItem('alias')
    const { id } = useParams();
    const [ campaign, setCampaign ] = useState()
    const [ loading, setLoading ] = useState(true)
    const [ authorization, setAuthorization ] = useState(true)
    const [ isOwner, setIsOwner ] = useState(false)

    let history = useHistory();

    const ValueWithLabel = ({ label, children }) => (
        <div>
          <Box margin={{ bottom: 'xxxs' }} color="text-label">
            {label}
          </Box>
          <div>{children}</div>
        </div>
      );

    

    useEffect(() => {
        setLoading(true)

        getCampaignsById(alias, id).then(
                (result) => {
                    if(result === "Not Authorized") {
                        setAuthorization(false)
                        props.showFlashbar(
                            "error", 
                            "Not Authorized", 
                            "",
                            ""
                        )
                        history.push("/")
                    }
                    //console.log(result)
                      if(result['coowners'].includes(alias)){
                        setIsOwner(true);
                      } else if (result['owner'].includes(alias)){
                        setIsOwner(true);
                      }

                    setCampaign(result)
                    setLoading(false)
                },
                (error) => {
                    console.log(error);
                    setLoading(false);
                }
            );
         }, []);

    let statusMapping = {
      "closed": "pending",
      "open": "success",
      "complete": "stopped"
    }

    let completeCampaign  = () => {
      //console.log("Completing the campaign")
      campaign['status'] = 'complete'
      //console.log(campaign)
      editCampaign(campaign).then(
          (result) => {
              //console.log(result)
              window.location.reload();
          },
          (error) => {
              console.log(error)
          }
      ) 
  }

    return (
        <div>
            {
                authorization &&
            <div>
              <SpaceBetween size="xxl">
                <Container header={
                    <Header 
                    variant="h2"
                    
                    actions={
                        <SpaceBetween direction="horizontal" size="xs">
                            { campaign?.['status'] ==="open" &&
                              <Button variant="primary" href={"/nomination/" + id}>
                                Nominate
                              </Button>
                            }
                              
                            { ((campaign?.['status'] ==="open" || campaign?.['status'] ==="closed") && isOwner )&&
                              <SpaceBetween direction="horizontal" size="xs">
                                <Button
                                  variant="secondary"
                                  onClick={() => {
                                    completeCampaign()
                                  }}
                                >
                                  Complete Campaign
                                </Button>
                                { isOwner &&
                                <ButtonDropdown
                                  items={[
                                    { text: "Edit", id: "edit", disabled: false },
                                    { text: "Email", id: "email", disabled: true}
                                  ]}
                                  onItemClick={({ detail }) =>{

                                    if(detail['id'] === "edit" && isOwner){
                                      //console.log("going to edit")
                                      history.push(
                                        "/campaign/new",
                                        {"campaign": campaign}
                                      )
                                    }
                                  }}
                                >
                                  Actions
                                </ButtonDropdown>
                              }
                              </SpaceBetween>
                            }
                          
                        </SpaceBetween>
                      }
                      description={campaign?.['description']}
                    >
                      {campaign?.['name']}
                    </Header>}>
                  <ColumnLayout columns={2} variant="text-grid">
                    <SpaceBetween size="l">
                      <ValueWithLabel label="owner">
                      {campaign?.['owner']}
                      </ValueWithLabel>
                      <ValueWithLabel label="coowners">
                      {campaign?.['coowners']?.toString()}
                      </ValueWithLabel>
                      <ValueWithLabel label="status">
                      <StatusIndicator type={statusMapping[campaign?.['status']]}>{campaign?.['status']}</StatusIndicator>
                      </ValueWithLabel>
                      <ValueWithLabel label="winner">
                        {campaign?.['winner']?.toString()}
                      </ValueWithLabel>
                    </SpaceBetween>
                    <SpaceBetween size="l">
                      <div style={{marginBottom:'-8px'}} >
                        {""}
                      </div>
                      <ValueWithLabel label="Type">
                      {campaign?.['award-type']} 
                      </ValueWithLabel>
                      <ValueWithLabel label="Start Date">
                      {campaign?.['start-date']}
                      </ValueWithLabel>
                      <ValueWithLabel label="End Date">
                      {campaign?.['end-date']}
                      </ValueWithLabel>
                    </SpaceBetween>
                  </ColumnLayout>
                  <ColumnLayout columns={1} variant="text-grid">
                    <SpaceBetween size="l">
                      <ValueWithLabel label="">
                      {""}
                      </ValueWithLabel>
                    </SpaceBetween>
                  </ColumnLayout>
                </Container>
                {
                  isOwner ? <ReviewCampaign campaignId={id} campaign={campaign} /> : null
                }
                
            </SpaceBetween>
            </div>
                }
        </div>
    )

}

export default CampaignsView;