import {
    Button,
    Container,
    Form,
    FormField,
    Header,
    Input,
    Select,
    SpaceBetween,
    Textarea as TextArea,
    DatePicker,
    Checkbox,
    Table,
    Box,
    Modal,
    RadioGroup,
    Multiselect,
    Icon,
    TokenGroup,
    Tiles
  }
  from "@awsui/components-react";

  import { useState, useEffect } from "react";
  import { getManagers } from "../Utils/API"
  import { devLogger } from "../Utils/Logging";

let ScopeModal = (props) => {

    let emptyScopeObj = {
        "type": "org",
        "alias": "",
        "ic": false,
        "manager": false
    }

    const [ scopeObj, setScopeObj ] = useState(emptyScopeObj)
    const [ managers, setManagers ] = useState([])
    const [ selectedManagers, setSelectedManagers ] = useState([])
    const [searchingManagers, setSearchingManagers ] = useState(false)

    //Validation Messages
    const [ aliasError, setAliasError ] = useState("")
    const [ roleError, setRoleError ] = useState("")

    let validateScopeObj = () => {
        let validationResult = true;
        if(scopeObj['alias']  === ""){
            setAliasError("Please Select a User")
            validationResult = false
        } else {
            setAliasError("")
        }
        if(!scopeObj['ic'] && !scopeObj['manager']){
            setRoleError("Please Select at least 1 Roler")
            validationResult = false
        }
        else {
            setRoleError("")
        }
        return validationResult
    }

    let getManagersWrapper = (chars) => {
        devLogger("Searching for Managers")
        //wait for 3 charws to be typed
        if(chars.length > 2){
          getManagers(chars).then(
            (result) => {
                //console.log(result)
                let managers = []
                result['Items'].forEach((t) => {
                  managers.push({ label: t.name, value: t.alias, description: t.alias})
                })
                setManagers(managers)
                setSearchingManagers(false)
            },
            (error) => {
                console.log(error);
            }
          );
        }
          
      }

      let findManagerById = (id) => {
        selectedManagers.forEach((x, i) => {
          if (x['value'] === id){
            console.log("found manager")
            console.log(x)
            return {label: x.label, value: x.value}
          } else {
            console.log("did not find manager")
            return { label: id, value: id }; //fallback
          }
        })
      }

    return(
        <Modal
                onDismiss={() => {
                    props.closeModal()
                    setScopeObj(emptyScopeObj)
                }}
                visible={props.visible}
                closeAriaLabel="Close modal"
                footer={
                    <Box float="right">
                    <SpaceBetween direction="horizontal" size="xs">
                        <Button variant="link"
                            onClick={() => {
                                props.closeModal()
                                setScopeObj(emptyScopeObj)
                            }}
                        >
                            Cancel
                        </Button>
                        <Button variant="primary"
                            onClick={()=>{
                              if (validateScopeObj()){
                                console.log(scopeObj)
                                //let newTasksList = newTasks
                                //newTask['id'] = newTasks.length
                                //newTasksList.push(newTask)
                                //setNewTasks(newTasksList)
                                //setCampaign({...campaign, ['tasks']: newTasksList})
                                //setModalVisible(false)
                                //setNewTask(emptyTask)
                               // console.log(campaign)
                               props.addScope(scopeObj)
                               setScopeObj(emptyScopeObj)
                               props.closeModal()
                              }
                                
                            }}
                        >Add Scope</Button>
                    </SpaceBetween>
                    </Box>
                }
                header={props.title}
            >
                    <Tiles
                      value={scopeObj.type}
                      onChange={({ detail }) => {
                        setScopeObj({ ...scopeObj, ['type']: detail.value, ['alias']: "", ['ic']: false, ['manager']: false})
                      }}
                      items={[
                        {
                          label: "Org",
                          description: "Add an Org for the Scope",
                          value: "org"
                        },
                        {
                          label: "Individual",
                          description: "Add an Individual for the Scope",
                          value: "user",
                          disabled: true
                        }
                      ]}
                    />

                   
                    <FormField label="Alias" description="" errorText={aliasError}>
                      <Select 
                        options={managers}
                        filteringType="manual"
                        placeholder="Search for Managers"
                        loadingText="Searching for Managers" 
                        statusType={searchingManagers? "loading" : "finished"}
                        onChange={(event) => {
                          //console.log(event.detail.selectedOptions)
                          setSelectedManagers(selectedManagers => [...selectedManagers, event.detail.selectedOption])
                          let managerAliases =[]
                            managerAliases.push(event.detail.selectedOption)
                          setScopeObj({...scopeObj, alias: event.detail.selectedOption.value})
                        }}
                        selectedOption={{value: scopeObj.alias, label: scopeObj.alias}}
                        onLoadItems={(e) => {
                          //console.log(e.detail.filteringText)
                          if(e.detail.filteringText.length > 2){
                            //console.log('searching for user')
                            getManagersWrapper(e.detail.filteringText)
                          } else {
                            setSearchingManagers(true)
                            setManagers([])
                          }
                        }}
                      />
                    </FormField>
                    <FormField label="Role" description="Select a Role for the Scope" errorText={roleError}>
                    {scopeObj.type==='org' &&
                    
                      <Checkbox
                          onChange={(event) => {
                            setScopeObj({...scopeObj, ['ic']: event.detail.checked})}
                          }
                          checked={scopeObj['ic']}
                      >
                        IC
                      </Checkbox>
                      }
                      {scopeObj.type==='org' &&
                      <Checkbox
                          onChange={(event) => {
                            setScopeObj({...scopeObj, ['manager']: event.detail.checked})}
                          }
                          checked={scopeObj['manager']}
                      >
                        Manager
                      </Checkbox>  
                      }
                      </FormField>
                           
            </Modal>
    )

}

export default ScopeModal;