import {
    Box,
    TextFilter,
    Button,
    Pagination,
    CollectionPreferences,
    Header,
    Table,
    Grid,
    StatusIndicator,
    Link,
    SpaceBetween,
    Icon,
    Modal,
    FormField,
    Textarea,
    Select,
    
  }
  from "@awsui/components-react";

  import { useEffect, useState } from "react";

  import { 
    addUpvote
} from "../Utils/API"

let VoteModal = (props) => {
    let alias = sessionStorage.getItem('alias')
    const [ vote, setVote] = useState({
        "alias": alias,
        "id": props.nomination['id'],
        "nom-timestamp": props.timestamp,
        "comment": ""
    })

    return (
        <div>
            <Modal
                visible={props.isVisible}
                onDismiss={() => {
                    props.setIsVisible(false)
                }}
                header={"Vote"}
            >
                <SpaceBetween direction="vertical" size="xl" >
                    <div>Vote for {props.nomination['name']}</div>
                    <FormField label="Situation"  >
                      <Textarea readOnly value={props.nomination.situation}  />
                  </FormField>
                  <FormField label="Action" >
                      <Textarea readOnly value={props.nomination.action}  />
                  </FormField>
                  <FormField label="Impact" >
                      <Textarea readOnly value={props.nomination.impact}  />
                  </FormField>
                  <FormField label="Leadership Principle" >
                      <Textarea readOnly value={props.nomination?.lp}  />
                  </FormField>
                    <FormField label="Comment (Optional)" description="Add a comment to your vote" errorText={""}>
                      <Textarea value={vote.comment} onChange={(event) => setVote({...vote, "comment": event.detail.value})} />
                  </FormField>
                  <Button
                    variant="primary"
                    onClick={(event) => {
                        console.log(vote)
                        console.log(props.nomination)
                        let newVote = vote;
                        newVote['id'] = props.nomination['id'];
                        newVote['nom-timestamp'] = props.nomination['timestamp']
                        addUpvote(newVote).then(
                            (result) => {
                                window.location.reload();
                                console.log(result)
                            },
                            (error) => {
                                window.location.reload();
                                console.log(error)
                            }
                            
                        )
                        setVote({
                            "alias": alias,
                            "id": "",
                            "nom-timestamp": "",
                            "comment": ""
                        })
                        props.setIsVisible(false);
                        
                    }}
                  >
                    Vote
                  </Button>
                </SpaceBetween>
            </Modal>

        </div>
    )
}

export default VoteModal;