/*
 * Midway Authenticator:
 * getMidwayToken checks for a JWT stored in the current session,
 * and if present returns the parsed JWT.
 * If not present, stores the current path in local storage,
 * redirects to obtain a JWT from Midway, and if successful,
 * stores the JWT and redirects back to the original path,
 * so the next call to getMidwayToken will return the parsed JWT.
 *
 * Usage:
 * import getMidwayToken from "./lib/MidwayToken";
 * const midwayToken = getMidwayToken();
 * console.log(midwayToken);
 *
 * Result is similar to:
 *
 * {
 * 	header: {
 * 		alg: "RS256"
 * 		kid: "8719070"
 * 		typ: "JWS"
 * 	}
 * 	payload: {
 * 		aal: 0
 * 		amr: "[\"pin\", \"u2f\", \"pes\"]"
 * 		aud: "nominate.smb.aws.dev" [<-- your site here]
 * 		auth_time: 1639666744
 * 		exp: 1639668148
 * 		ial: 0
 * 		iat: 1639667248
 * 		iss: "https://midway-auth.amazon.com"
 * 		midway_trust_score: null
 * 		mwi: 0
 * 		nonce: "qTz8QyN5VX2pozeD2tAvuQzB0KIMy575vxwozYqbhV9UI3HQYNdlCHYSiSpsbk9y"
 * 		posture_check: 1
 * 		req_id: "3d3a4ca2-3aae-4895-b0c6-c9e728a589ed"
 * 		sub: "powrsm" [<-- user's id here ]
 * 		trust_score: 1
 * 	}
 *  ...
 * } 
 *
 * Partly inspired from 
 * https://code.amazon.com/packages/MidwayIdentityCredentialProvider/blobs/d72613cf102b4c207dffe2cc3f3edad9ed1e5b97/--/src/token-retriever.ts#L51
 */

import jwt from 'jsonwebtoken';
const MIDWAY_DOMAIN = "midway-auth.amazon.com";
const SITE = process.env.REACT_APP_URL

function generateNonce() {
  let nonce = "";
  const characterSet =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  for (let i = 0; i < 64; i += 1) {
    nonce += characterSet.charAt(
      Math.floor(Math.random() * characterSet.length),
    );
  }
  return nonce;
}

function buildQuery(parameters) {
  return Object.keys(parameters).reduce(
    (accumulator, key) => `${accumulator + key}=${parameters[key]}&`,
    "?",
  );
}

function buildRedirectUrl() {
  const queryParams = {
    client_id: encodeURIComponent(window.location.host),
    redirect_uri: encodeURIComponent(window.location.href),
    response_type: "id_token",
    scope: "openid",
    nonce: generateNonce(),
  };

  return `https://${MIDWAY_DOMAIN}/login?next=/SSO/redirect${encodeURIComponent(
    buildQuery(queryParams),
  )}`;
}

function getMidwayToken() {
  if (!window.location.href.includes(SITE)) {
    // development mode
    return { payload: { sub: 'developer' } };
  }

  let token;
  if (window.location.href.includes('id_token=')) {
    token = new URLSearchParams(window.location.search).get('id_token');
    sessionStorage.setItem('midway_id_token', token);

    // navigate to original path
    window.location.href = sessionStorage.getItem('midway_original_path');

    // EXITS PAGE EXECUTION
    return null;
  }
  if ((token = sessionStorage.getItem('midway_id_token')) != null) {
    return jwt.decode(token, { complete: true });
  }

  // save current path
  sessionStorage.setItem('midway_original_path', window.location.href);

  // navigate to authentication
  window.location.href = buildRedirectUrl();

  // halt page execution
  throw new Error("No valid midway token found; authenticating.");
};

export default getMidwayToken;
