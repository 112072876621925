import {
    Box,
    Button,
    Table,
    SpaceBetween,
    Modal
  }
  from "@awsui/components-react";

let EndorsementsModal = (props) => {
    //console.log(props.nomination)
    
    return (
        <Modal
        onDismiss={() => {
            props.setShowEndorsementsModal(false)
        }}
        visible={props.visible}
        closeAriaLabel="Close modal"
        footer={
            <Box float="right">
            <SpaceBetween direction="horizontal" size="xs">
                <Button variant="link"
                    onClick={() => {
                        props.setShowEndorsementsModal(false)
                    }}
                >
                    Close
                </Button>
            </SpaceBetween>
            </Box>
        }
        header="Endorsements"
    >
        <Table
        columnDefinitions={[
            {
                name: "alias",
                header: "Endorser",
                cell: e => e['alias'],
                sortingField: "alias"
            },
            {
                name: "comment",
                header: "Comment",
                cell: e => e['comment'],
                sortingField: "comment"
            }
        ]}
        items={props?.nomination?.['endorsements']}
        wrapLines
        resizableColumns
        >

        </Table>
    </Modal>
    )

}

export default EndorsementsModal;