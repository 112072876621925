import { devLogger } from "./Logging"

const STAGE = process.env.REACT_APP_STAGE
devLogger("stage")
devLogger(STAGE)
let SERVICE_URLS ={}

const PROD_URLS = {
  "USERS_SERVICE_URL": "https://8ql8emyph7.execute-api.us-east-1.amazonaws.com/prod",
  "REPORTS_SERVICE_URL": "https://qhhdlduwkc.execute-api.us-east-1.amazonaws.com/prod",
  "CAMPAIGNS_SERVICE_URL": "https://qlsa6a4wt5.execute-api.us-east-1.amazonaws.com/prod",
  "AWARDS_SERVICE_URL": "https://1mimdiv8nc.execute-api.us-east-1.amazonaws.com/prod",
  "NOMINATIONS_SERVICE_URL": "https://wxu1alind0.execute-api.us-east-1.amazonaws.com/prod",
  "TASKS_SERVICE_URL": "https://nu70hxtjhf.execute-api.us-east-1.amazonaws.com/prod"
}

const DEV_URLS = {
  "USERS_SERVICE_URL": "https://tgm32oonr5.execute-api.us-east-1.amazonaws.com/prod",
  "REPORTS_SERVICE_URL": "https://fkwip62dbf.execute-api.us-east-1.amazonaws.com/prod",
  "CAMPAIGNS_SERVICE_URL": "https://6saoy8xqbc.execute-api.us-east-1.amazonaws.com/prod",
  "AWARDS_SERVICE_URL": "https://jd3h9hztne.execute-api.us-east-1.amazonaws.com/prod",
  "NOMINATIONS_SERVICE_URL": "https://zz81dpw038.execute-api.us-east-1.amazonaws.com/prod",
  "TASKS_SERVICE_URL": "https://0dup8m5qyf.execute-api.us-east-1.amazonaws.com/prod"
}

if(STAGE === "PROD"){
  SERVICE_URLS = PROD_URLS
} else if(STAGE === "DEV") {
  SERVICE_URLS = DEV_URLS
} else {
  console.log("ERROR: Operating Mode Not Defined. Specify Prod or Dev.")
}


async function internalFetch(fetchUrl) {
    return new Promise((resolve, reject) => {
      // eslint-disable-next-line
      /* global fetch */
      fetch(fetchUrl, {
          headers: {
            "Authorization": "Bearer " + sessionStorage.getItem("midway_id_token")
          },
        })
        .then(
          res => res.json(),
          error => {
            console.log("fetch", error);
            reject(error);
          }
        )
        .then(
          (result) => {
            if (result && result.errorMessage) {
              reject(result.errorMessage);
            }
            else if (result && result.body) {
              resolve(result.body);
            }
            else {
              resolve(result);
            }
          },
          (error) => {
            console.log("fetch", error);
            reject(error);
          }
        );
    });
  }

  async function getNominations(campaignId) {
    let fetchUrl = SERVICE_URLS.NOMINATIONS_SERVICE_URL + "/nominations/search/campaign?campaignId=" + campaignId;
    return internalFetch(fetchUrl);
  }

  async function exportNominationsByCampaign(campaignId) {
    let fetchUrl = SERVICE_URLS.NOMINATIONS_SERVICE_URL + "/nominations/export/campaign?id=" + campaignId;
    return internalFetch(fetchUrl);
  }

  //deprecated
/*   async function addNomination(nomination) {
    let fetchUrl = "https://ij3u7ccfhl.execute-api.us-east-1.amazonaws.com/prod/nomination/add?nomination=" + nomination.type;
    return internalFetch(fetchUrl);
  } */

  async function addNomination(nomination) {
    let fetchUrl = SERVICE_URLS.NOMINATIONS_SERVICE_URL + "/nominations";
    console.log("*******")
    //console.log(nomination)
    return new Promise((resolve, reject) => {
      // eslint-disable-next-line
      /* global fetch */
      fetch(fetchUrl, {
          method: 'POST',
          headers: {
            'Authorization': 'Bearer ' + sessionStorage.getItem("midway_id_token"),
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(nomination)
        })
        .then(res => res.json())
        .then(
          (result) => {
            if (result && result.errorMessage) {
              reject(result.errorMessage);
            }
            else {
              resolve(result);
              //console.log(result)
            }
          },
          (error) => {
            console.log("saveItem", error);
            reject(error);
          }
        );
    });
  }

  async function addUpvote(upvote) {
    let fetchUrl = SERVICE_URLS.NOMINATIONS_SERVICE_URL + "/nominations/upvote";
    //console.log("*******")
    //console.log(upvote)
    return new Promise((resolve, reject) => {
      // eslint-disable-next-line
      /* global fetch */
      fetch(fetchUrl, {
          method: 'POST',
          headers: {
            'Authorization': 'Bearer ' + sessionStorage.getItem("midway_id_token"),
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(upvote)
        })
        .then(res => res.json())
        .then(
          (result) => {
            if (result && result.errorMessage) {
              reject(result.errorMessage);
            }
            else {
              resolve(result);
              //console.log(result)
            }
          },
          (error) => {
            console.log("saveItem", error);
            reject(error);
          }
        );
    });
  }

  async function addEndorsement(endorsement) {
    let fetchUrl = SERVICE_URLS.NOMINATIONS_SERVICE_URL + "/nominations/endorsement";
    //console.log("*******")
    //console.log(upvote)
    return new Promise((resolve, reject) => {
      // eslint-disable-next-line
      /* global fetch */
      fetch(fetchUrl, {
          method: 'POST',
          headers: {
            'Authorization': 'Bearer ' + sessionStorage.getItem("midway_id_token"),
            'Accept': 'application/json',
            "Access-Control-Allow-Origin": "*",
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(endorsement)
        })
        .then(res => res.json())
        .then(
          (result) => {
            if (result && result.errorMessage) {
              reject(result.errorMessage);
            }
            else {
              resolve(result);
              //console.log(result)
            }
          },
          (error) => {
            console.log("saveItem", error);
            reject(error);
          }
        );
    });
  }


  async function getCampaings(alias) {
    if(alias === undefined) {
      alias = ""
    }
    if(alias.includes('+')){
      alias = alias.replace('+', '%2B')
    }
    //console.log(alias)
    let fetchUrl = SERVICE_URLS.CAMPAIGNS_SERVICE_URL + "/campaigns/search/alias?alias=" + alias
    //console.log(fetchUrl)
    return internalFetch(fetchUrl)
  }

  async function getCampaignsByOwner(alias) {
    if(alias === undefined) {
      alias = ""
    }
    if(alias.includes('+')){
      alias = alias.replace('+', '%2B')
    }
    //console.log(alias)
    let fetchUrl = SERVICE_URLS.CAMPAIGNS_SERVICE_URL + "/campaigns/search/owner?alias=" + alias
    //console.log(fetchUrl)
    return internalFetch(fetchUrl)
  }

  async function getCampaignsById(alias, campaignId) {
    if(alias === undefined) {
      return(null)
    }
    if(campaignId === undefined) {
      return(null)
    }
    if(alias.includes('+')){
      alias = alias.replace('+', '%2B')
    }
    //console.log(alias)
    let fetchUrl = SERVICE_URLS.CAMPAIGNS_SERVICE_URL + "/campaigns/search/id?alias=" + alias + "&campaignId=" + campaignId
    //console.log(fetchUrl)
    return internalFetch(fetchUrl)
  }


  async function getAwardsByManager(managerAlias) {
    let fetchUrl = SERVICE_URLS.AWARDS_SERVICE_URL + "/awards/search/manager?alias=" + managerAlias
    return internalFetch(fetchUrl)
  }

  async function getTasksByAward(awardId) {
    let fetchUrl = SERVICE_URLS.TASKS_SERVICE_URL + "/tasks/search/award?awardId=" + awardId
    return internalFetch(fetchUrl)
  }

  async function getAwardsByAlias(alias) {
    if(alias.includes('+')){
      alias = alias.replace('+', '%2B')
    }
    let fetchUrl = SERVICE_URLS.AWARDS_SERVICE_URL + "/awards/search/alias?alias=" + alias
    return internalFetch(fetchUrl)
  }

  async function getAwardTypes() {
    let fetchUrl = "https://ij3u7ccfhl.execute-api.us-east-1.amazonaws.com/prod/awards/types"
    return internalFetch(fetchUrl)
  }

  async function getEligibleUsers(campaignId) {
    let fetchUrl = SERVICE_URLS.USERS_SERVICE_URL + "/users/campaign?id=" + campaignId
    return internalFetch(fetchUrl)
  }

  async function getManagers(chars) {
    let fetchUrl = SERVICE_URLS.USERS_SERVICE_URL + "/managers?chars=" + chars
    return internalFetch(fetchUrl)
  }

  async function getManagersByCampaign(id) {
    let fetchUrl = SERVICE_URLS.USERS_SERVICE_URL + "/users/campaign/manager/?id=" + id
    return internalFetch(fetchUrl)
  }

  async function getUserDetails(alias) {
    if(alias.includes('+')){
      alias = alias.replace('+', '%2B')
    }
    let fetchUrl = SERVICE_URLS.USERS_SERVICE_URL + "/users/search/alias?alias=" + alias
    return internalFetch(fetchUrl)
  }


  async function addCampaign(campaign) {
    let fetchUrl = SERVICE_URLS.CAMPAIGNS_SERVICE_URL + "/campaigns";
    console.log("*******")
    console.log(campaign)
    return new Promise((resolve, reject) => {
      // eslint-disable-next-line
      /* global fetch */
      fetch(fetchUrl, {
          method: 'POST',
          headers: {
            'Authorization': 'Bearer ' + sessionStorage.getItem("midway_id_token"),
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(campaign)
        })
        .then(res => res.json())
        .then(
          (result) => {
            if (result && result.errorMessage) {
              reject(result.errorMessage);
            }
            else {
              resolve(result);
              console.log(result)
            }
          },
          (error) => {
            console.log("saveItem", error);
            reject(error);
          }
        );
    });
  }

  async function editCampaign(campaign) {
    let fetchUrl = SERVICE_URLS.CAMPAIGNS_SERVICE_URL + "/campaigns";
    console.log("*******")
    console.log(campaign)
    return new Promise((resolve, reject) => {
      // eslint-disable-next-line
      /* global fetch */
      fetch(fetchUrl, {
          method: 'PUT',
          headers: {
            'Authorization': 'Bearer ' + sessionStorage.getItem("midway_id_token"),
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(campaign)
        })
        .then(res => res.json())
        .then(
          (result) => {
            if (result && result.errorMessage) {
              reject(result.errorMessage);
            }
            else {
              resolve(result);
              console.log(result)
            }
          },
          (error) => {
            console.log("saveItem", error);
            reject(error);
          }
        );
    });
  }

  async function addAward(nomination) {
    let fetchUrl = SERVICE_URLS.AWARDS_SERVICE_URL + "/awards";
    console.log("*******")
    console.log(nomination)
    return new Promise((resolve, reject) => {
      // eslint-disable-next-line
      /* global fetch */
      fetch(fetchUrl, {
          method: 'POST',
          headers: {
            'Authorization': 'Bearer ' + sessionStorage.getItem("midway_id_token"),
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(nomination)
        })
        .then(res => res.json())
        .then(
          (result) => {
            if (result && result.errorMessage) {
              reject(result.errorMessage);
            }
            else {
              resolve(result);
              console.log(result)
            }
          },
          (error) => {
            console.log("saveItem", error);
            reject(error);
          }
        );
    });
  }

  async function addTask(task) {
    let fetchUrl = SERVICE_URLS.TASKS_SERVICE_URL + "/tasks";
    console.log("*******")
    console.log(task)
    return new Promise((resolve, reject) => {
      // eslint-disable-next-line
      /* global fetch */
      fetch(fetchUrl, {
          method: 'POST',
          headers: {
            'Authorization': 'Bearer ' + sessionStorage.getItem("midway_id_token"),
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(task)
        })
        .then(res => res.json())
        .then(
          (result) => {
            if (result && result.errorMessage) {
              reject(result.errorMessage);
            }
            else {
              resolve(result);
              console.log(result)
            }
          },
          (error) => {
            console.log("saveItem", error);
            reject(error);
          }
        );
    });
  }

  async function completeTask(task) {
    let fetchUrl = SERVICE_URLS.TASKS_SERVICE_URL + "/tasks/complete/";
    console.log("*******")
    console.log(task)
    return new Promise((resolve, reject) => {
      // eslint-disable-next-line
      /* global fetch */
      fetch(fetchUrl, {
          method: 'POST',
          headers: {
            'Authorization': 'Bearer ' + sessionStorage.getItem("midway_id_token"),
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(task)
        })
        .then(res => res.json())
        .then(
          (result) => {
            if (result && result.errorMessage) {
              reject(result.errorMessage);
            }
            else {
              resolve(result);
              console.log(result)
            }
          },
          (error) => {
            console.log("saveItem", error);
            reject(error);
          }
        );
    });
  }

  async function addFeedback(feedback) {
    let fetchUrl = SERVICE_URLS.REPORTS_SERVICE_URL + "/reports/feedback";
    console.log("*******")
    console.log(feedback)
    return new Promise((resolve, reject) => {
      // eslint-disable-next-line
      /* global fetch */
      fetch(fetchUrl, {
          method: 'POST',
          headers: {
            'Authorization': 'Bearer ' + sessionStorage.getItem("midway_id_token"),
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(feedback)
        })
        .then(res => res.json())
        .then(
          (result) => {
            if (result && result.errorMessage) {
              reject(result.errorMessage);
            }
            else {
              resolve(result);
              console.log(result)
            }
          },
          (error) => {
            console.log("saveItem", error);
            reject(error);
          }
        );
    });
  }


  export { getNominations, addNomination, getCampaings, getCampaignsByOwner, getCampaignsById, getAwardsByManager, getTasksByAward, getAwardsByAlias, addCampaign, editCampaign, addAward, addTask, completeTask, getAwardTypes, getEligibleUsers, getManagers, addFeedback, getUserDetails, addUpvote, addEndorsement, getManagersByCampaign, exportNominationsByCampaign }