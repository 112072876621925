import { CognitoUserAttribute, CognitoUser, AuthenticationDetails } from 'amazon-cognito-identity-js';
import { useState } from 'react';
import UserPool from '../UserPool';
import { useHistory } from "react-router-dom";
import { devLogger } from '../Utils/Logging';




let Login = () => {
    let history = useHistory();
    const [userName, setUserName ] = useState('')
    const [ password, setPassword ] = useState('')

    let alias = sessionStorage.getItem('alias')

    if (alias != null && alias != ''){
        //devLogger('user logged in')
        //devLogger(alias)
        history.push('/home')
    }

    const onSubmit = (e) => {
        e.preventDefault();
        
        const user = new CognitoUser({
            Username: userName,
            Pool: UserPool,
        })
    
        const authDetails = new AuthenticationDetails({
            Username: userName,
            Password: password
        })
    
        user.authenticateUser(authDetails, {
            onSuccess: data => {
                console.log('onSuccess', data)
                sessionStorage.setItem("alias", userName);
                window.location.reload();
                history.push("/home")
            },
    
            onFailure: data =>{
                console.log('onFailure', data)
            },
    
            newPasswordRequired: data =>{
                console.log('newPasswordRequired', data)
            }
        })
      };


    return (
        <div>
            <form onSubmit={onSubmit}>
            Username:
            <input
            type="text"
            value={userName.toLowerCase().trim()}
            onChange={(e) => setUserName(e.target.value)}
            />
            Password:
            <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            />
            <br />
            <button type="submit">Login</button>
            <button onClick={(e) =>history.push("/register")}>Register</button>
        </form>
        
        </div>

    )

}

export default Login;