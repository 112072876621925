
import {
  Button,
  CollectionPreferences,
  Container,
  Form,
  FormField,
  Header,
  Input,
  Select,
  SpaceBetween,
  Box,
  Textarea as TextArea,
  RadioGroup
}
from "@awsui/components-react";
import { useState, useEffect } from "react";

import { useHistory, useParams } from "react-router-dom";
import { addNomination } from "../Utils/API"
import { getEligibleUsers, getCampaignsById, getNominations, add, addEndorsement } from "../Utils/API"


function findSelected(inItem, items) {
  if (inItem) {
    for (let i in items) {
      //console.log(items[i])
      if (items[i].id === inItem){
          return { value: items[i].value, label: items[i].label, id: items[i].id, type: items[i].type }
      }
    }
    console.log('findSelected not found', inItem);
  }
  return null;
}

function findLp(inItem, items) {
  if (inItem) {
    for (let i in items) {
      //console.log(items[i])
      if (items[i].value === inItem){
          return { value: items[i].value, label: items[i].label }
      }
    }
    console.log('findSelected not found', inItem);
  }
  return null;
}

function findUser(inItem, users) {
  if (inItem) {
    for (let i in users) {
      //console.log(items[i])
      if (users[i].value === inItem){
          console.log(users[i])
          return { label: users[i].label, value: users[i].value, description: users[i].description }
      }
    }
    console.log('findSelected not found', inItem);
  }
  return null;
}

let onSave = (nomination, showFlashbar) => {
  console.log("Submiting Nomination")
  //console.log(nomination)
  addNomination(nomination).then(
      (result) => {
          console.log(result)
      },
      (error) => {
          console.log(error)
      }
  )
  showFlashbar("success", "Nomination Successfully Created")
}

let onEndorsement = (endorsement, showFlashbar) => {

  //console.log(nomination)
  addEndorsement(endorsement).then(
      (result) => {
          console.log(result)
      },
      (error) => {
          console.log(error)
      }
  )
  showFlashbar("success", "Endorsement Successfully Submitted")
}

var m = new Date();
var dateString = m.getUTCFullYear() +"-"+ (m.getUTCMonth()+1) +"-"+ m.getUTCDate() + " " + m.getUTCHours() + ":" + m.getUTCMinutes() + ":" + m.getUTCSeconds();                                 

let alias = sessionStorage.getItem('alias')

let emptyNom = {
  "campaign-id": "",
  "nominee-alias": "",
  "nominator-alias": alias,
  "timestamp" : dateString,
  "situation": "",
  "action": "",
  "impact": "",
  "type": "",
  "endorsements": [],
  "votes": [],
  "lp": ""
}
let NominationEdit = (props) => {
  let history = useHistory();
  const { id } = useParams();
  const [nomination, setNom] = useState(emptyNom);
  const [campaigns, setCampaigns] = useState([])
  const [ campaign, setCampaign ] = useState({})
  const [eligibleUsers, setEligibleUsers] = useState([])
  const [searchingEligibleUsers, setSearchingEligibleUsers] = useState(false)
  const [selectedUser, setSelectedUser] = useState([])
  const [searchingCampaigns, setSearchingCampaigns] = useState(false)
  const [ authorization, setAuthorization ] = useState(true)
  const [ aliasError, setAliasError ] = useState("")
  const [ situationError, setSituationError ] = useState("")
  const [ actionError, setActionError ] = useState("")
  const [ impactError, setImpactError ] = useState("")
  const [ isUpvote, setIsUpvote ] = useState(false)
  const [ previousNominations, setPreviousNominations ] = useState([])
  const [ previousNominationId, setPreviousNominationId ] = useState("")
  const [ upvoteCommentError, setUpvoteCommentError ] = useState("")
  const [ lpsError, setLpsError ] = useState("")
  const [upvote, setUpvote ] = useState({
    "id": "",
    "nom-timestamp": "",
    "alias": alias,
    "comment": ""
  })

  const lpsList = [
    { value: "Customer Obsession", label: "Customer Obsession" },
    { value: "Ownership", label: "Ownership" },
    { value: "Invent and Simplify", label: "Invent and Simplify" }, 
    { value: "Are Right, A Lot", label: "Are Right, A Lot" }, 
    { value: "Learn and Be Curious", label: "Learn and Be Curious" }, 
    { value: "Hire and Develop the Best", label: "Hire and Develop the Best" }, 
    { value: "Insist on the Highest Standards", label: "Insist on the Highest Standards" }, 
    { value: "Think Big", label: "Think Big" },
    { value: "Bias for Action", label: "Bias for Action" },  
    { value: "Frugality", label: "Frugality" }, 
    { value: "Earn Trust", label: "Earn Trust" }, 
    { value: "Dive Deep", label: "Dive Deep" }, 
    { value: "Have Backbone; Disagree and Commit", label: "Have Backbone; Disagree and Commit" }, 
    { value: "Deliver Results", label: "Deliver Results" }, 
    { value: "Strive to be Earths Best Employer", label: "Strive to be Earths Best Employer" }, 
    { value: "Success and Scale Bring Broad Responsibility", label: "Success and Scale Bring Broad Responsibility" }, 
  ]

  let validateUpvoteForm = () => {
    let validationResult = true;
    if(upvote['comment'] === ""){
      setUpvoteCommentError("Please add a Comment")
      validationResult = false
    } else {
      setUpvoteCommentError("")
    }
    return(validationResult)
  }

  let validateForm = () => {
    let validationResult = true;
    if(nomination['nominee-alias']  === ""){
      setAliasError("Please Select a Nominee")
      validationResult = false
    } else {
      setAliasError("")
    }
    if(nomination['situation'] === ""){
      setSituationError("Please Describe the Situation")
      validationResult = false
    } else {
      setSituationError("")
    }
    if(nomination['action'] === ""){
      setActionError("Please Describe the Action")
      validationResult = false
    } else {
      setActionError("")
    }
    if(nomination['impact'] === ""){
      setImpactError("Please Describe the Impact")
      validationResult = false
    } else {
      setImpactError("")
    }
    if(nomination['lp'] === ""){
      setLpsError("Please Select and LP")
      validationResult = false
    } else {
      setLpsError("")
    }
    return(validationResult)
  }

  const ValueWithLabel = ({ label, children }) => (
    <div>
      <Box margin={{ bottom: 'xxxs' }} color="text-label">
        {label}
      </Box>
      <div>{children}</div>
    </div>
  );

  let getEligibleUsersWrapper = (campaignId) => {
    //console.log(campaignId)
    setSearchingEligibleUsers(true)
    getEligibleUsers(campaignId).then(
      (result) => {
          //console.log(result)
          let users = result.map(item => {
            return {label: item.name, value: item.alias, description: item.alias}
          })
          //console.log(users)
          setEligibleUsers(users)
          setSearchingEligibleUsers(false)
          
      },
      (error) => {
          console.log(error)
          setSearchingEligibleUsers(false)
      }
  )
  }

  let checkForPreviousNomination = (alias) => {
    let isNominated = false
    previousNominations.forEach((prevNom) => {
      //console.log(prevNom['nominee-alias'])
      //console.log(alias)
      if(prevNom?.['nominee-alias'] === alias){
        //console.log("found")
        isNominated = true
        setUpvote({...upvote, "id": prevNom['id'], "nom-timestamp": prevNom['timestamp']})
        setNom({...nomination, situation: prevNom?.['situation'], action: prevNom?.['action'], impact: prevNom?.['impact'], lp: prevNom?.['lp'] })
      }
    })
    return  isNominated
  }

  useEffect(() => {
      //do what you need here
  
    //if(alias !== null){
      //setSearchingCampaigns(true)
      alias = sessionStorage.getItem('alias')
   //console.log(alias)
   getCampaignsById(alias, id).then(
       (result) => {
         if(result === "Not Authorized") {
           setAuthorization(false)
           props.showFlashbar(
               "error", 
               "Not Authorized", 
               "",
               ""
           )
           history.push("/")
         }
         else {
           setCampaign(result)
           setNom({...nomination, 'campaign-id': result.id, type: result['award-type'], 'nominee-alias': ''})
           getEligibleUsersWrapper(result.id)
         }
         //console.log(result)
         //let campaignOptions = []
           //campaignOptions.push({ "label": result.name, "value": result.name, "id": result.id, "type": result['award-type'], "campaign-description": result['description'], description : "Campaign Scope: " + result['scope'] + " | Manager Eligible: " + result['ManagerNominee'] + " | IC Eligible: " + result['ICNominee']})
           //console.log(e)
         //setCampaigns(campaignOptions)
         //setSearchingCampaigns(false)
       },
       (error) => {
           console.log(error);
       }
   ).then(
    getNominations(id).then(
      (result) => {
        setPreviousNominations(result['Items'])
        //console.log(result)
      },
      (error) => {
        console.log(error);
    }
    )
   );

    //}
   
  }, []);

  return (
    
      <div>
        { authorization &&
          <Form
              actions={
                <SpaceBetween direction="horizontal" size="xs">
                  {!isUpvote ? 
                    <Button 
                      variant="primary" 
                      onClick={() => {
                        //console.log(nomination)
                        if (validateForm()){
                            onSave(nomination, props.showFlashbar);
                            setNom(emptyNom)
                            history.push("/")
                          }
                      }}>
                        Submit Nomination
                      </Button>
                    : null}
                  { isUpvote ? 
                    <Button 
                      variant="primary" 
                      onClick={() => {
                        //console.log(nomination)
                        if (validateUpvoteForm()){
                            onEndorsement(upvote, props.showFlashbar)
                            //onSave(nomination, props.showFlashbar);
                            //setNom(emptyNom)
                            setUpvote({
                              "id": "",
                              "nom-timestamp": "",
                              "alias": alias,
                              "comment": ""
                            })
                            history.push("/")
                          }
                      }}>
                        Submit Endorsement
                      </Button>
                    : null
                  }
                </SpaceBetween>
              }
            >
  
          <SpaceBetween direction="vertical" size="l">
            
            <Container header={
                <Header 
                  variant="h2">
                  New Nomination
                </Header>
            }>
              <SpaceBetween direction="vertical" size="l">
                  
                  <ValueWithLabel label="Campaign">
                    {campaign?.name}
                  </ValueWithLabel>
                  <ValueWithLabel label="Campaign Description">
                    {campaign?.description}
                  </ValueWithLabel>
                  <FormField label="Nominee Alias" description="Enter the alias of the nominee" errorText={aliasError}>
                      <Select
                        filteringType="auto"
                        selectedOption={selectedUser}
                        onChange={({ detail }) =>{
                            //console.log(detail)
                            if(checkForPreviousNomination(detail.selectedOption.value)){
                              setIsUpvote(true)
                            } else {
                              setIsUpvote(false)
                              setNom({...nomination, 'nominee-alias': detail.selectedOption.value, 'situation': "", "action": "", 'impact': "", 'endorsements': [], 'votes': []});
                            }
                            
                            setSelectedUser(detail.selectedOption)
                            
                          }
                        }
                        onLoadItems={(e) => {
                          //console.log(e.detail.filteringText)
                        }

                        }
                        deselectAriaLabel={e => `Remove ${e.label}`}
                        options={eligibleUsers}
                        placeholder="Search for Eligible Users"
                        selectedAriaLabel="Selected"
                        statusType={searchingEligibleUsers? "loading" : "finished"}
                        loadingText="Searching for users"
                      />
                      
                  </FormField>
                  { isUpvote ? 
                    <div style={{color: "#4287f5"}}>
                      Selected User has been previously nominated for this campaign. To upvote their nomination please add a comment and submit the upvote.
                    </div>
                  : null}
                  <FormField label="Situation" description="What was the situation" errorText={situationError} >
                      <TextArea disabled={isUpvote} value={nomination.situation} onChange={(event) => setNom({...nomination, situation: event.detail.value})} />
                  </FormField>
                  <FormField label="Action" description="What was the action" errorText={actionError}>
                      <TextArea disabled={isUpvote} value={nomination.action} onChange={(event) => setNom({...nomination, action: event.detail.value})} />
                  </FormField>
                  <FormField label="Impact" description="What was the impact" errorText={impactError}>
                      <TextArea disabled={isUpvote} value={nomination.impact} onChange={(event) => setNom({...nomination, impact: event.detail.value})} />
                  </FormField>
                  <FormField label="Leadership Principle" description="Select One Leadership Principle" errorText={lpsError}>
                    <Select
                    disabled={isUpvote}
                      onChange={( event ) => {
                        console.log(event.detail.selectedOption.value)
                        setNom({...nomination, lp: event.detail.selectedOption.value})}
                      }
                      selectedOption={findLp(nomination?.lp, lpsList)}
                      options={lpsList}
                    />
                  </FormField>
                  {isUpvote ?
                  <FormField label="Comment" description="Add a comment to your upvote" errorText={upvoteCommentError}>
                      <TextArea disabled={!isUpvote} value={upvote.comment} onChange={(event) => setUpvote({...upvote, "comment": event.detail.value})} />
                  </FormField>

                  : null }

              </SpaceBetween>
            </Container>
          </SpaceBetween>
        </Form>

      }                  
      </div>

      
  )
}

export default NominationEdit;