import { getAwardsByManager } from "../Utils/API"

import { getTasksByAward, addTask, completeTask } from "../Utils/API"

import { useState, useEffect } from "react";

import {
    Cards,
    Box,
    TextFilter,
    Button,
    Pagination,
    CollectionPreferences,
    Header,
    Table,
    Grid,
    Modal,
    SpaceBetween,
    FormField,
    Input,
    DatePicker
  }
  from "@awsui/components-react";

let alias =  sessionStorage.getItem('alias')


let ReportsAwards = () => {

    let emptyTask = {
        name: "",
        due: ""
    }

    const [awards, setAwards] = useState([])
    const [tasks, setTasks] = useState([])
    const [loading, setLoading] = useState(true);
    const [selectedAwards,setSelectedAwards] = useState([]);
    const [selectedTasks,setSelectedTasks] = useState([]);
    const [modalVisible, setModalVisible] = useState(false);
    const [newTask, setNewTask] = useState(emptyTask);

    

    useEffect(() => {

        getAwardsByManager(alias).then(
                (result) => {
                    console.log(result)
                    setAwards(result.Items)
                    setLoading(false)
                },
                (error) => {
                    console.log(error);
                    setLoading(false);
                }
            );
         }, []);

    let getTasks = (awardId) => {
        getTasksByAward(awardId).then(
            (result) => {
                console.log("tasks")
                console.log(result.Items)
                setTasks(result.Items)
            },
            (error) => {
                console.log(error)
            }
        )
        console.log(awardId)

    }

    let saveTask = (task) => {
        console.log("Submiting Task")
        //console.log(nomination)
        addTask(task).then(
            (result) => {
                console.log(result)
                getTasks(selectedAwards[0]['id'])
            },
            (error) => {
                console.log(error)
            }
        )
        
    }

    let compTask = (task) => {
        console.log("Completing Task")
        //console.log(nomination)
        completeTask(task).then(
            (result) => {
                console.log(result)
                getTasks(selectedAwards[0]['id'])
            },
            (error) => {
                console.log(error)
            }
        )
        
      }
    

    return (
        <div>
            <Cards
                loading={loading}
                selectionType="single"
                onSelectionChange={({ detail }) =>{
                    setSelectedAwards(detail.selectedItems)
                    console.log(detail.selectedItems)
                    getTasks(detail.selectedItems[0].id)
                }
                    
                }
                selectedItems={selectedAwards}
                ariaLabels={{
                    itemSelectionLabel: (e, t) => `select ${t.name}`,
                    selectionGroupLabel: "Item selection"
                }}
                cardDefinition={{
                    header: e => e.alias,
                    sections: [
                    {
                        id: "photo",
                        width: "50",
                        content: (item) => <div style={{minHeight:'50px'}} >
                            {
                                <img 
                                    alt={item.alias}
                                    height="48px" 
                                    style={{border: "1px solid #aaa"}} 
                                    src={"https://internal-cdn.amazon.com/badgephotos.amazon.com/?uid="+item.alias}
                                >
                                </img>
                            }   

                            </div>
                    },
                    {
                        id: "campaign-name",
                        header: "Campaign Name",
                        content: e => e['campaign-name']
                    },
                    {
                        id: "award-type",
                        header: "Award Type",
                        content: e => e['award-type']
                    },
                    {
                        id: "timestamp",
                        header: "Date Awarded",
                        content: e => e.timestamp
                    },
                    
                    ]
                }}
                cardsPerRow={[
                    { cards: 1 },
                    { minWidth: 200, cards: 3 }
                ]}
                items={awards}
                loadingText="Loading Tasks"
                trackBy="id"
                visibleSections={["photo", "award-type", "timestamp", "campaign-name" ]}
                empty={
                    <Box textAlign="center" color="inherit">
                    <b>No Tasks</b>
                    <Box
                        padding={{ bottom: "s" }}
                        variant="p"
                        color="inherit"
                    >
                        No tasks to display.
                    </Box>
                    </Box>
                }
                header={
                    <Header>
                    Active Awards for Direct Reports
                    </Header>
                }
                

            />
            
            <div>
                <Grid
                    gridDefinition={[{ colspan: 10 }, { colspan: 2 }]}
                    disableGutters
                >
                    <Button 
                        variant="primary"
                        onClick={() => {
                            setModalVisible(true)
                        }}
                        disabled={selectedAwards.length == 0 ? true : false}
                    >
                        Add Task
                    </Button>
                    <Button 
                        variant="primary"
                        style={{alignItems:"flex-end"}}
                        disabled={selectedTasks.length == 0 ? true : false}
                        onClick={() => {
                            compTask(selectedTasks)
                        }}
                    >
                        Complete Task
                    </Button>
                </Grid>
                <br></br>
            </div>
            <div>
            <Table
                onSelectionChange={({ detail }) =>
                    setSelectedTasks(detail.selectedItems)
                }
                selectedItems={selectedTasks}

                columnDefinitions={[
                    {
                        name: "name",
                        header: "Task",
                        cell: e => e.name,
                        sortingField: "name"
                    },
                    {
                        id: "due",
                        header: "Due",
                        cell: e => e.due,
                        sortingField: "alt"
                    },
                    {   
                        id: "status", 
                        header: "Status", 
                        cell: e => e.status 
                    }
                    
                ]}
                isItemDisabled={(item) => {
                    let disabled = (!(item['status'] === "active"))
                    return disabled
                }}
                items={tasks}
                loadingText="Loading Tasks"
                selectionType="single"
                trackBy="id"
                empty={
                    <Box textAlign="center" color="inherit">
                    <b>No Tasks</b>
                    <Box
                        padding={{ bottom: "s" }}
                        variant="p"
                        color="inherit"
                    >
                       Select an Award to Display Tasks.
                    </Box>
                    </Box>
                }
                header={
                    <Header>
                    Manager Tasks
                    </Header>
                }
                
                
                />
            </div>
            <Modal
                onDismiss={() => {
                    setModalVisible(false)
                    setNewTask(emptyTask)
                }}
                visible={modalVisible}
                closeAriaLabel="Close modal"
                footer={
                    <Box float="right">
                    <SpaceBetween direction="horizontal" size="xs">
                        <Button variant="link"
                            onClick={() => {
                                setModalVisible(false)
                                setNewTask(emptyTask)
                            }}
                        >
                            Cancel
                        </Button>
                        <Button variant="primary"
                            onClick={()=>{
                                console.log(newTask)
                                saveTask(newTask)
                                setModalVisible(false)
                                setNewTask(emptyTask)
                            }}
                        >Add</Button>
                    </SpaceBetween>
                    </Box>
                }
                header="New Task"
            >
                    <FormField label="Task Name" description="" errorText={""}>
                        <Input value={newTask.name} onChange={
                            (event) => {setNewTask({ ...newTask, "name": event.detail.value, "award-id": selectedAwards[0]['id']})
                            }
                        } />
                    </FormField>
                    <FormField label="Due Date" description="" errorText={""}>
                        <DatePicker 
                            value={newTask.due} 
                            onChange={(event) => setNewTask({ ...newTask, "due": event.detail.value})} 
                            nextMonthAriaLabel="Next month"
                            placeholder="YYYY/MM/DD"
                            previousMonthAriaLabel="Previous month"
                            todayAriaLabel="Today"
                        />
                    </FormField>
            </Modal>
        </div>
    )

}

export default ReportsAwards;